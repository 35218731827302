import React, { useEffect } from 'react'
import AmritjalNavbar from '../../components/Amritjal_hp/AmritjalNavbar'
import AmritjalFooter from '../../components/Amritjal_hp/AmritjalFooter'
import HeroSection from '../../components/InflunencerHomepage/HeroSection'
import WhatweOffer from '../../components/Trip/WhatweOffer'
import Journey from '../../components/Trip/Journey'
import TripItinerary from '../../components/Trip/TripItinerary'
import trip_1_desktop from '../../assets/Explore/trip_1_desktop.jpeg';
import trip_2_desktop from '../../assets/Explore/trip_2_dv.jpeg';
import trip_1_mobile from '../../assets/Explore/trip_1_mobile.jpeg';
import trip_2_mobile from '../../assets/Explore/trip_2_mv.jpeg';
import WhatsAppSupport from '../../components/Support/WhatsAppSupport'
import TripCard from '../../components/Trip/TripCard'
import TripExclusion from '../../components/Trip/TripExclusion'
import Accordion from '../../components/Trip/Accordion'

const TripHomePage = () => {
  useEffect(() => {
      window.scrollTo(0, 0); // Ensure scrolling to the top happens immediately
  
    }, []);

    const day1Items = [
      {
        title: '🌙 Midnight Excitement Begins!',
        time: '12:00 AM',
        description: 'Depart from Delhi 🚗 (Buckle up for an epic road trip with great vibes and travel tunes!)',
      },
      {
        title: '🌅 Sunrise Serenity in Rishikesh',
        time: '05:00 AM',
        description: 'Arrive in Rishikesh, sip on hot chai ☕ (Feel the crisp mountain air as you prepare for an amazing day ahead.)',
      },
      {
        time: '5:45-6:30 AM',
        description: 'Witness the divine Ganga Aarti at Triveni Ghat 🙏 (Immerse yourself in the magical energy of Rishikesh as the holy chants and diyas light up the river!)',
      },
      {
        time: '6:30-7:30 AM',
        description: 'Fuel up with Maggi & Bun Maska at a cozy tea stall 🍜🥯 (There’s nothing better than hot, buttery bun maska and Maggi in the hills!)',
      },
      {
        title: '🏕️ Settle In & Gear Up for Adventure!',
        time: '8:00 AM',
        description: 'Check-in at the camp, freshen up & soak in the vibes 🏕️ (The sound of the river, the fresh air—pure bliss!)',
      },
      {
        time: '9:00 AM',
        description: 'Hearty breakfast at the camp 🍽️ (Power up for an action-packed day!)',
      },
      {
        title: '🚣‍♂️ Conquer the Rapids!',
        time: '11:00 AM-1:30 PM',
        description: 'River Rafting Adventure 🌊 (Get your adrenaline pumping as you ride the wild rapids, cliff jump into icy waters, and experience the raw power of the Ganges!)',
      },
      {
        title: '🌿 Relax, Refuel & Explore',
        time: '2:00 - 2:30 PM',
        description: 'Return to camp, shower & unwind 🚿 (Nothing beats a refreshing shower after an intense rafting session!)',
      },
      {
        time: '3:00-4:30 PM',
        description: 'Café hopping & lunch 🍕☕ (Chill at the best spots like Little Buddha Café or Ganga Beach Café—good food, great views!)',
      },
      {
        time: '4:30-6:00 PM',
        description: 'Free time for personal activities ✨ (Stroll through vibrant marketplaces, visit ancient temples, or treat yourself to a relaxing spa!)',
      },
      {
        title: '🏍️ Ride Through Paradise!',
        time: '6:00-8:00 PM',
        description: 'Rent a scooty & cruise through Rishikesh’s scenic roads 🌄 (Feel the wind in your hair as you cross the iconic New Jhula—an experience like no other!)',
      },
      {
        title: '🔥 Bonfire, Music & Chill!',
        time: '8:00-10:00 PM',
        description: 'Unwind at the camp with a bonfire & dinner 🍛🔥 (Sing, share stories, and enjoy a perfect starry night by the riverside!)',
      },
    ];
    

    const day2Items = [
      {
        title: '🌞 Start Your Day with Inner Peace',
        time: '7:30 AM - 9:00 AM',
        description: 'Morning Yoga by the Ganges 🧘‍♀️ (Breathe, stretch, and let the positive energy of Rishikesh flow through you!)',
      },
      {
        time: '10:00 AM - 11:30 AM',
        description: 'Sound Healing Therapy 🎶 (Let the calming vibrations wash away your stress and rejuvenate your soul!)',
      },
      {
        title: '🍛 Fuel Up Before the Journey',
        time: '12:30 PM',
        description: 'Lunch at an aesthetic cafe 🍛 (Savor delicious local flavors before heading out!)',
      },
      {
        title: '🛕 Temple & Nature Expedition!',
        time: '1:30 PM',
        description: 'Depart for Neelkanth Mahadev Temple 🚗 (A scenic drive through lush Himalayan roads to one of the most powerful Shiva temples!)',
      },
      {
        time: '3:30 PM',
        description: 'Stop by a waterfall 🌊 (Dip your feet in the cold water, take stunning photos, and embrace nature!)',
      },
      {
        title: '🍽️ One Last Bite Before Goodbye!',
        time: '5:00 PM',
        description: 'Final food stop 🍽️ (Grab some quick bites before hitting the road!)',
      },
      {
        time: '6:00 PM',
        description: 'Depart for Delhi 🏙️ (Take home unforgettable memories and maybe start planning your next trip already! 😉✨)',
      },
    ];
    

  return (
    <div>
      <AmritjalNavbar/>
      <WhatsAppSupport/>
      <HeroSection/>
      <WhatweOffer/>
      <TripItinerary/>
    <Journey 
        title="DAY 1: Arrival, Thrills & Exploration" 
        timelineItems={day1Items} 
        desktopImageSrc={trip_1_desktop} 
        mobileImageSrc={trip_1_mobile} 
      />
      
      <Journey 
        title="DAY 2: Wellness & Self Healing" 
        timelineItems={day2Items} 
        desktopImageSrc={trip_2_desktop} 
        mobileImageSrc={trip_2_mobile} 
      />
      <TripCard/>
      <TripExclusion/>
      <Accordion/>
      <AmritjalFooter/>
    </div>
  )
}

export default TripHomePage
