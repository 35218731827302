import React from 'react';
import styles from '../../styles/screens/Trip/TripItinerary.module.css';
import { useNavigate } from "react-router-dom";

const TripItinerary = () => {
  const navigate = useNavigate();

  const handleApplyClick = () => {
    navigate("/dharmikx/hidden-gem-gateway");
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>TRIP ITINERARY</h1>
      
      <div className={styles.tripDetails}>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Departure from</div>
          <div className={styles.detailValue}>Delhi</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Location</div>
          <div className={styles.detailValue}>Rishikesh</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Price per person</div>
          <div className={styles.detailValue}>Rs. 8000</div>
        </div>
      </div>

      <div className={styles.dateContainer}>
        <div className={styles.dateItem}>29-30 March'25</div>
        <div className={styles.dateItem}>05-06 April'25</div>
        <div className={styles.dateItem}>12-13 April'25</div>
      </div>

      <p className={styles.disclaimer}>
        *Only for select individuals. Apply now and the team will contact you shortly
      </p>
      <p className={styles.disclaimer}>
        *20 people group. Accepted age group 20-30 years old.
      </p>

      <button className={styles.applyButton} onClick={handleApplyClick}>Apply Now</button>
    </div>
  );
}

export default TripItinerary;