import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";

import firebaseConfig from "../../firebase";
import logo from "../../assets/logo.png";
import assignBottlesAndUpdateAll from "../../utils/AmritJalBottleIdAssigner";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DharmikxRazorPayOnline = async (productId, orderId, productPrice, phone, productDescription, userName, userEmail="contact@dharmik.in", quantity, city, state, pincode, coupon) => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  console.log(userName)

  script.onload = () => {
    const options = {
      key_id: process.env.RZP_KEY,
      order_id: orderId,
      name: "Dharmik",
      description: productDescription,
      image: logo,
      prefill: {
        name: userName,
        email: userEmail,
        contact: phone,
      },
      notes: "Price = " + productPrice,

      handler: async function (response) {
        // Handle successful payment here
        try {
          await  updateDharmikxPaymentStatusFDB(phone, orderId, "paid", true, response, productId, quantity, userName, city, state, pincode, coupon, productPrice);
          // dharmikapp.in/dharmikx/checkout/amritjal/amritjal-spray
          window.location.href = window.location.origin + "/dharmikx/checkout/" + productId + "/" + orderId;
        } catch(e) {
          console.error("ERROR IN SUCCESS HANDLER : ", e);
        }
      },
      modal: {
        ondismiss: async function () {
          // Handle the case where the user cancels the payment
          try {
            await updateDharmikxPaymentStatusFDB(phone, orderId, "dismiss", false, null, productId, quantity, userName, city, state, pincode, coupon, productPrice);
            window.location.href = window.location.origin + "/dharmikx/checkout/" + productId + "/" + orderId;
          }catch(e) {
            console.error("ERROR IN ON DISMISS FUNCTIONM HANDLER : ", e);
          }
        }
      },

      method: {
        netbanking: true,
        card: true,   
        upi: true,         
        wallet: false,
        emi: false,      
        bank_transfer: true,
        paylater: false
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

async function updateDharmikxPaymentStatusFDB(phone, orderId, payStatus, paymentSuccess=false, response=null, productId, quantity, userName, city, state, pincode, coupon, price) {
  const orderRef = doc(db, "orders", orderId)
  const orderHistoryRef = doc(db, "order_history", phone);
  const mkOrdersRef = doc(db, "dharmikxorders", orderId);

  try {
    const orderSnap = await getDoc(orderRef);
    const orderData = orderSnap.data();
    
    if (orderSnap.exists()) {
      await setDoc(orderRef, {[orderId]: {
        ...orderData[orderId],
        rzp_status: payStatus,
        rzp_payment_id: response ? response.razorpay_payment_id : null,
        rzp_payment_signature: response ? response.razorpay_signature : null,
        status: paymentSuccess===true ? "PAYMENT_SUCCESS" : "PAYMENT_FAILED",
        updated_time: serverTimestamp(),
      }}, {merge:true});
    }
  }catch (e) {
    console.error("ERROR IN FETCHING ORDER DOC FROM ORDERID : ", e);
  }

  try {
    const orderHistorySnap = await getDoc(orderHistoryRef);
    const orderHistoryData = orderHistorySnap.data();
    
    if (orderHistorySnap.exists()) {
      await setDoc(orderHistoryRef, {[orderId]: {
        ...orderHistoryData[orderId],
        rzp_status: payStatus,
        rzp_payment_id: response ? response.razorpay_payment_id : null,
        rzp_payment_signature: response ? response.razorpay_signature : null,
        status: paymentSuccess===true ? "PAYMENT_SUCCESS" : "PAYMENT_FAILED",
        updated_time: serverTimestamp(),
      }}, {merge:true});
    }
  }catch (e) {
    console.error("ERROR IN FETCHING ORDER HISTORY DOC FROM ORDERID : ", e);
  }

  try {
    const mkOrderSnap = await getDoc(mkOrdersRef);
    const mkOrderData = mkOrderSnap.data();
    
    if (mkOrderSnap.exists()) {
      await setDoc(mkOrdersRef, {[orderId]: {
        ...mkOrderData[orderId],
        rzp_status: payStatus,
        rzp_payment_id: response ? response.razorpay_payment_id : null,
        rzp_payment_signature: response ? response.razorpay_signature : null,
        status: paymentSuccess===true ? "PAYMENT_SUCCESS" : "PAYMENT_FAILED",
        updated_time: serverTimestamp(),
      }}, {merge:true});
    }
  }catch (e) {
    console.error("ERROR IN FETCHING ORDER DOC FROM ORDERID : ", e);
  }
  
  if(payStatus=== "paid" && (productId === "amritjal-spray" || productId === "amritjal-rose")) {
    try{
      await assignBottlesAndUpdateAll(productId, orderId, quantity, userName, phone, city, state, pincode, coupon, price);
    }catch(error) {
      console.log("Error in generating and updating bottle ID for amritjal-spray ", error);
    }
  }
}

export default DharmikxRazorPayOnline;
