import styles from "../../styles/screens/Influencer/components/HeroSection.module.css"
import hero from '../../assets/amritjal_hp/chr 1.png'

const HeroSection = () => {
  return (
    <section className={styles.heroContainer}>
      <div className={styles.imageWrapper}>
        <div className={styles.glowEffect}></div>
        <img
          src={hero}
          alt="Meditation figure with chakra symbols"
          className={styles.heroImage}
        />
      </div>
      <div className={styles.tag}>Pure · Sacred · Divine</div>
    </section>
  )
}

export default HeroSection