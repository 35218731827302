import React from 'react';
import styles from '../../styles/screens/Trip/WhatweOffer.module.css';
import icon1 from "../../assets/amritjal_hp/Guru.png"
import icon2 from "../../assets/amritjal_hp/Field.png"
import icon3 from "../../assets/amritjal_hp/Lotus.png"
import icon4 from "../../assets/amritjal_hp/Field (1).png"

const offerings = [
  {
    icon: icon1,
    title: 'Discover Inner Peace and Self-Healing',
    description: 'Embark on a journey of self-reflection and healing through yoga, meditation, and sound therapy. Release emotional blockages and reconnect with your inner self in the serene environment of Rishikesh.',
  },
  {
    icon: icon2,
    title: 'Reconnect with Nature for Emotional Growth',
    description: 'Surround yourself with the tranquility of nature—waterfalls, lush landscapes, and riverside serenity—helping you to relax, rejuvenate, and foster emotional well-being.',
  },
  {
    icon: icon3,
    title: 'Embrace Spiritual Awakening',
    description: 'Experience the sacred energy of Rishikesh with visits to Neelkanth Mahadev Temple and the soulful Ganga Aarti. Let the spiritual ambiance guide you toward clarity and harmony.',
  },
  {
    icon: icon4,
    title: 'Cultivate a better you',
    description: 'Through asanas, pranayama, and mindfulness practices, enhance self-awareness, build resilience, and leave with tools to nurture a healthier, more balanced version of yourself.',
  }
];

const WhatweOffer = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>What we offer!</h1>
      </header>
      <div className={styles.offeringsGrid}>
        {offerings.map((offering, index) => (
          <div key={index} className={styles.offeringCard}>
            <div className={styles.titleContainer}>
              <img src={offering.icon} alt="" className={styles.icon} />
              <h3 className={styles.offeringTitle}>{offering.title}</h3>
            </div>
            <p className={styles.offeringDescription}>{offering.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatweOffer;