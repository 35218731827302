import React, { useState, useRef } from "react";
import styles from "../../styles/screens/Trip/accordion.module.css";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionData = [
    {
      question: "What’s included in the package?",
      answer: [
        "✅ Travel (Delhi–Rishikesh–Delhi)",
        "✅ Riverside camp stay 🏕️",
        "✅ Meals as per itinerary 🍽️",
        "✅ Yoga & sound healing 🧘‍♂️",
        "✅ Scooty rental 🛵",
        "✅ Bonfire & music night 🔥",
      ],
    },
    {
      question: "What’s not included?",
      answer: [
        "❌ Personal expenses (shopping, extra meals)",
        "❌ Food at cafés/restaurants (except breakfast & late-night snacks at the camp).",
        "❌ Rafting (₹1000 per person) – to be added upon confirmation.",
        "❌ Medical expenses or travel insurance.",
        "❌ Petrol for scooty rental.",
        "❌ Anything not specifically mentioned in the inclusions.",
      ],
    },
    {
      question: "Is river rafting safe?",
      answer: [
        "Yes! ✔️ Life jackets, helmets & expert guides ensure safety.",
        "Great for beginners too!",
      ],
    },
    {
      question: "What should I pack?",
      answer: [
        "🎒 Comfortable clothes, extra for rafting",
        "👟 Shoes/slippers for activities",
        "🧴 Sunscreen, sunglasses, toiletries",
        "📷 Camera (optional)",
      ],
    },
    {
      question: "What are the stay arrangements?",
      answer: [
        "🏕️ Twin/triple-sharing tents with bedding",
        "🚿 Attached/common washrooms",
        "🔥 Bonfire & chill zone",
      ],
    },
    {
      question: "Can I join solo?",
      answer: ["Yes! Solo travelers, couples, groups & families – all are welcome!"],
    },
    {
      question: "How to book?",
      answer: [
        "📩 DM on Instagram",
        "📞 Call/WhatsApp +917906671564",
        "💳 Pay to confirm your slot",
      ],
    },
    {
      question: "What’s the cancellation policy?",
      answer: [
        "❌ No refunds for cancellations within 7 days",
        "✅ Partial refund for 7+ days prior",
        "✅ Full refund (minus processing fees) for 15+ days",
      ],
    },
  ];

  return (
    <div className={styles.accordionContainer}>
      <h2 className={styles.heading}>FAQ’S</h2>
      <div className={styles.accordionWrapper}>
        {accordionData.map((item, index) => (
          <div key={index} className={styles.accordionItem}>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span
                className={`${styles.arrow} ${
                  activeIndex === index ? styles.arrowActive : ""
                }`}
              >
                ▼
              </span>
            </button>
            <div
              className={`${styles.accordionContent} ${
                activeIndex === index ? styles.active : ""
              }`}
              ref={(el) => (contentRefs.current[index] = el)}
              style={{
                maxHeight: activeIndex === index ? contentRefs.current[index]?.scrollHeight : 0,
              }}
            >
              <ul className={styles.accordionAnswer}>
                {item.answer.map((line, i) => (
                  <li key={i}>{line}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
