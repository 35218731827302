import React from 'react';
import styles from '../../../styles/screens/AmritjalProductDetail/VrindavanPrasad/Benefits.module.css';

const Benefits =  () => {
  return (
    <section className={styles.benefitsSection}>
      <div className={styles.benefitsContainer}>
        <h1 className={styles.benefitsTitle}>BENEFITS</h1>

        <div className={styles.benefitsGrid}>
          <div className={styles.benefitCard}>
            <h2>Spiritual Blessings in your home</h2>
            <p>Bring home items blessed in the holy land where Krishna performed His leelas.</p>
          </div>

          <div className={styles.benefitCard}>
            <h2>Perfect for Gifting</h2>
            <p>Share the divine blessings of Shri Krishna with your loved ones this Holi through a thoughtfully curated Prasad Box.</p>
          </div>

          <div className={styles.benefitCard}>
            <h2>Symbol of Devotion</h2>
            <p>Each item is a reminder of your devotion and connection to Shri Krishna and Vrindavan.</p>
          </div>

          <div className={styles.benefitCard}>
            <h2>Celebrate Holi with Krishna's Blessings</h2>
            <p>A unique addition to your celebrations, symbolizing Krishna's divine presence in your home.</p>
          </div>

          <div className={styles.benefitCard}>
            <h2>Authenticity & Divine Connection</h2>
            <p>Sourced directly from the holy town of Vrindavan and prasad offered at the revered Shri Banke Bihari Temple in Vrindavan.</p>
          </div>

          <div className={styles.benefitCard}>
            <h2>True and Transparency</h2>
            <p>Every Box is carefully packed and blessed, ensuring it reaches you in perfect condition.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
