import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'; 
import styles from '../../styles/screens/AmritjalBooking/ResultPage.module.css';
//import product from "../../assets/amritjal_hp/spray_icon.png"
import { db } from "../../utils/firestoredb";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import DharmikxRazorPayOnline from '../Payment/DharmikxRazorPayOnline';
import {Oval} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import WhatsAppSupport from "../Support/WhatsAppSupport";

const ResultPage = () => {
  const { productId, orderId } = useParams(); 
  const [status, setStatus] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const [orderData, setOrderData] = useState(null);
  //const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderRef = doc(db, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        if (orderSnapshot.exists()) {
          setOrderData(orderSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching Order Details: ", error);
      }
    };
  
    fetchOrderDetails();
  }, [orderId]);
  
  useEffect(() => {
    fetch("https://checkorder-ptmfa2eama-uc.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus(data.status);
        console.log("STATUS : ", data.status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [orderId]);

  useEffect(() => {
    if (orderData && status) {
      if (status === "paid" || orderData[orderId].rzp_status === "paid") {
        setIsSuccess(true);
      }
    }
  }, [orderData, status, orderId]);

  const retryPayment = async => {
    DharmikxRazorPayOnline(orderData[orderId].productId, orderId, orderData[orderId].price, phone, orderData[orderId].productDescription, orderData[orderId].name, orderData[orderId].email);
  };

  const goToHome = () => {
    navigate("/dharmikx");
  }

  const trackOrder = () => {
    if(orderData[orderId].awb) {
      const url = "https://www.delhivery.com/track-v2/package/" + orderData[orderId].awb; // Replace with your desired URL
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      window.open("https://www.delhivery.com/tracking", "_blank", "noopener,noreferrer");
    }
  };

  const getTrackingAWB = () => {
    if(orderData[orderId].awb) {
      const url = "https://www.delhivery.com/track-v2/package/" + orderData[orderId].awb; // Replace with your desired URL
      return url;
    }
    return "https://www.delhivery.com/tracking";
  }

  const renderButton = () => {
    if(status && orderData) {
        if (status === "paid" || orderData[orderId].rzp_status === "paid") {
            return (
              <button className={styles.actionButton} onClick={trackOrder}>
                {"Track Order"}
              </button>
            );
          } else if (status === "attempted" || status === "created" || orderData[orderId].rzp_status === "dismiss") {
            return (
              <button className={styles.actionButton} onClick={() => retryPayment()}>
                {"Retry Payment"}
              </button>
            );
          }
    }
  };

  const paymentString = () => {
    if(status && orderData) {
      if(status === "paid" || orderData[orderId].rzp_status === "paid") return "Yay! Your Payment Went Through!";
      else {
        return "Oops! Payment Didn't Go Through";
      }
    }
  };

  function formatTimestamp(timestamp) {
    return timestamp.toDate().getDate() + " " + 
           timestamp.toDate().toLocaleString("en-US", { month: "short" });
  };

  function formatTimestampPlusDays(timestamp, daysToAdd = 0) {
    const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
    date.setDate(date.getDate() + daysToAdd); // Add the specified number of days
    return date.getDate() + " " + date.toLocaleString("en-US", { month: "short" });
  };

  //if(!isLoggedIn) navigate("/" + orderData[orderId].productId);
  if (!orderData && !status) {
      return(
        <Oval 
          color="#ff8e4d"
          height="20%"
          width="20%"
          strokeWidth={4}
          ariaLabel="Loading"
          wrapperStyle={{position: "fixed", top: "45%", left: "45%"}}
          wrapperClass="loader"
        />
    );
  } else {
    console.log("ORDER DETAILS: ", orderData);
  }

  if(orderData && status)
  return (
    <div className={`${styles.resultContainer} ${isSuccess ? styles.success : styles.failure}`}>
      <div className={styles.statusIcon}>
        <div className={styles.innerCircle}>
          {isSuccess ? (
            <span className={styles.checkIcon}>&#10003;</span>
          ) : (
            <span className={styles.crossIcon}>&#10007;</span>
          )}
        </div>
      </div>

      <h1 className={styles.statusTitle}>
        {paymentString()}
      </h1>
      <p className={styles.orderNumber}>Order Number: {orderId}</p>
      {isSuccess ? (
        <p className={styles.thankYou}>Thank you for shopping</p>
      ) : (
        <button className={styles.actionButton} onClick={() => goToHome()}>
                {"Go to Home"}
      </button>
      )
    }

      {isSuccess && (
        <div className={styles.trackingContainer}>
          <div className={styles.trackingSteps}>
            <div className={styles.trackingLine}></div>
            <div className={`${styles.step} ${styles.stepActive}`}>
              <div className={styles.stepIcon}>
                <span className={styles.checkIconSmall}>&#10003;</span>
              </div>
              <span className={styles.stepDate}>{formatTimestamp(orderData[orderId].updated_time)}</span>
              <span>Ordered</span>
            </div>
            <div className={styles.step}>
              <div className={styles.stepIcon}>
                <span className={styles.packageIcon}>&#128230;</span>
              </div>
              <span className={styles.stepDate}>{formatTimestampPlusDays(orderData[orderId].updated_time, 2)}</span>
              <span>Shipped</span>
            </div>
            <div className={styles.step}>
              <div className={styles.stepIcon}>
                <span className={styles.packageIcon}>&#128230;</span>
              </div>
              <span className={styles.stepDate}>{formatTimestampPlusDays(orderData[orderId].updated_time, 5)}</span>
              <span>Delivered</span>
            </div>
          </div>
        </div>
      )}

      <div className={styles.orderCard}>
        <div className={styles.productInfo}>
          <img
            src={orderData[orderId].image}
            alt="Product"
            className={styles.productImage}
          />
          <div className={styles.productDetails}>
            <span className={styles.productName}>Product ID: {productId}</span>
            <span className={styles.orderId}>Order Number: {orderId}</span>
            {isSuccess && 
            <a href={getTrackingAWB()} className={styles.trackLink} target="_blank" rel="noopener noreferrer">
              Track Shipment
            </a>
            }
          </div>
        </div>
        <div className={styles.priceQty}>
          <div className={styles.price}>{orderData[orderId].price}</div>
          <div className={styles.quantity}>Quantity : {orderData[orderId].quantity}</div>
        </div>
      </div>
      {renderButton()}
      <WhatsAppSupport/>
    </div>
  );
};

export default ResultPage;
