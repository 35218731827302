import {
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import DharmikxRazorPayOnline from "./DharmikxRazorPayOnline";

import { db } from "../../utils/firestoredb";

const CreateDharmikxOrderFDB = async (orderId, phone, productId, finalPrice, productDescription ,productImage, productQuantity="1", coupon="", name, street, pincode, city, state, email, quantity=1, discountAmount=0) => {

    const orderDocData = {[orderId]:{
        phone,
        platform: "web",
        productName: productId,
        productDescription: productDescription,
        price: finalPrice,
        orderId: orderId,
        productId: productId,
        image: productImage,
        quantity: productQuantity,
        discount : discountAmount.toString(),
        currency : "INR",
        coupon : coupon,
        name: name,
        email : email,
        address : {
          street : street,
          pincode : pincode,
          city : city,
          state : state
        },
        status: "PAYMENT_INITIATED",
        created_time: serverTimestamp(),
        updated_time: serverTimestamp(),
      }};

    // orders doc
    try {
      const ordersRef = doc(db, "orders", orderId);
      await setDoc(ordersRef, orderDocData);
    } catch (e) {
      console.error("Error in adding document in ORDERS COLLECTION: ", e);
    }

      // order history doc
    try {
      const orderHistoryRef = doc(db, "order_history", phone);
      await setDoc(orderHistoryRef, orderDocData, {merge : true});
      } catch(e) {
          console.error("ERROR IN CREATING ORDER DOC IN USER HISTORY :", e);
      }

    // dharmikxorders
    try {
        const ordersRef = doc(db, "dharmikxorders", orderId);
        await setDoc(ordersRef, orderDocData);
      } catch (e) {
        console.error("Error in adding document in ORDERS COLLECTION: ", e);
    }

    DharmikxRazorPayOnline(
      productId,
      orderId,
      finalPrice,
      phone,
      productDescription,
      name,
      email,
      quantity,
      city,
      state,
      pincode,
      coupon
    );
};

export default CreateDharmikxOrderFDB;
