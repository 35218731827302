import { useEffect } from "react";
import styles from "../../styles/components/Support/WhatsAppSupport.module.css"

const WhatsAppSupport = () => {
    useEffect(() => {
        const button = document.createElement("div");
        button.className = styles.floatingWhatsapp;
    
        const link = document.createElement("a");
        link.id = "whatsapp-link";
        link.target = "_blank";
        link.rel = "noopener noreferrer";
    
        const img = document.createElement("img");
        img.src = "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg";
        img.alt = "WhatsApp";
        img.className = styles.whatsappIcon;
    
        link.appendChild(img);
        button.appendChild(link);
        document.body.appendChild(button);
    
        const updateWhatsAppLink = () => {
          const phoneNumber = "+917906671664";
          const message = encodeURIComponent("Hello, I need support regarding: ");
          const currentUrl = encodeURIComponent(window.location.href);
          const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}%20${currentUrl}`;
    
          document.getElementById("whatsapp-link").href = whatsappUrl;
        };
    
        updateWhatsAppLink();
        window.addEventListener("popstate", updateWhatsAppLink);
    
        return () => {
          button.remove();
          window.removeEventListener("popstate", updateWhatsAppLink);
        };
      }, []);
    
      return null;
    };
  
  export default WhatsAppSupport;