import React, { useEffect, useState } from 'react';
import styles from '../../styles/screens/Ledger/Ledger.module.css';
import AmritjalNavbar from '../../components/Amritjal_hp/AmritjalNavbar';
import InfluencerFooter from '../../components/InflunencerHomepage/InfluencerFooter';
import logo from "../../assets/amritjal_hp/dharmik_logo.png";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../utils/firestoredb';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from "posthog-js/react";
import WhatsAppSupport from '../../components/Support/WhatsAppSupport';

const LedgerPage = () => {
  const [userExists, setUserExists] = useState(false);
  const [showFullAmritJal, setShowFullAmritJal] = useState(false);
  const [showFullRoseFragrance, setShowFullRoseFragrance] = useState(false);
  const [amritjalList, setAmritjalList] = useState([]);
  const [rosejalList, SetRosejalList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const posthog = usePostHog();

  const navigateToAmritJalBooking = () => {
    navigate("/dharmikx/product/amritjal-rose");
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure scrolling to the top happens immediately
  }, []);

  useEffect(() => {
    const phoneNumber = localStorage.getItem('phoneNumber');
    setUserExists(!!phoneNumber);
  }, []);

  useEffect(() => {
    const fetchLedgerData = async () => {
      const amritjalLedgerRef = doc(db, "dharmikxproductledger", "amritjal-spray");
      const rosejalLedgerRef = doc(db, "dharmikxproductledger", "amritjal-rose");
      const amritjalLedgerSnap = await getDoc(amritjalLedgerRef);
      const rosejalLedgerSnap = await getDoc(rosejalLedgerRef);

      if (amritjalLedgerSnap.exists()) {
        const amritjalList = amritjalLedgerSnap.data();
        setAmritjalList(amritjalList.ledger.reverse());
      }

      if (rosejalLedgerSnap.exists()) {
        const rosejalList = rosejalLedgerSnap.data();
        SetRosejalList(rosejalList.ledger.reverse());
      }
    };
    fetchLedgerData();
  }, []);

    useEffect(() => {
        posthog.capture("$pageview");
      }, [posthog]);

  const toggleAmritJalList = (e) => {
    e.preventDefault();
    setShowFullAmritJal(!showFullAmritJal);
    setShowFullRoseFragrance(false);
  };

  const toggleRoseFragranceList = (e) => {
    e.preventDefault();
    setShowFullRoseFragrance(!showFullRoseFragrance);
    setShowFullAmritJal(false);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = showFullAmritJal
    ? amritjalList.slice(indexOfFirstItem, indexOfLastItem)
    : showFullRoseFragrance
    ? rosejalList.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const totalPages = showFullAmritJal
    ? Math.ceil(amritjalList.length / itemsPerPage)
    : showFullRoseFragrance
    ? Math.ceil(rosejalList.length / itemsPerPage)
    : 0;

  return (
    <>
      <AmritjalNavbar />
      <WhatsAppSupport/>
      <div className={styles.container}>
        <div className={styles.card}>
          {/* Profile Section */}
          {userExists ? (
            <div className={styles.profile}>
              <div className={styles.profileSection}>
              <img src={logo} alt="Profile" className={styles.profileImage} />
              <span className={styles.profileName}>Rakesh Sharma</span>
              </div>
              <div className={styles.profileInfo}>
                <h1>Amrit Jal Limited Edition</h1>
                <h2 className={styles.roseFragrance}>With Rose Fragrance</h2>
                <div className={styles.bottleId}>
                  <span>Bottle ID.</span>
                  <span className={styles.id}>201</span>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.profile}>
              <div className={styles.profileSection}>
              <img src={logo} alt="Default Profile" className={styles.profileImage} />
              <span className={styles.profileName}>Update Info</span>
              </div>
              <div className={styles.profileInfo}>
                <h1>Amrit Jal Limited Edition</h1>
                {/* <h2 className={styles.roseFragrance}>With Rose Fragrance</h2> */}
                <div className={styles.orderButton} onClick={navigateToAmritJalBooking}>
                  Order now to get your unique Bottle ID
                </div>
              </div>
            </div>
          )}

          {/* Public Ledger Button */}
          <div className={styles.ledgerButtonContainer}>
            <button className={styles.ledgerButton}>PUBLIC LEDGER</button>
          </div>

          {/* Full List View */}
          {(showFullAmritJal || showFullRoseFragrance) ? (
            <div className={styles.productSection}>
              <h3 className={styles.productTitle}>
                {showFullAmritJal ? "Amrit Jal" : "Amrit Jal Rose Fragrance"}
                <span className={styles.stockInfo}>(8000 Limited Stock)</span>
              </h3>

              <div className={styles.ledgerTable}>
                <div className={styles.tableHeader}>
                  <span>Unique Bottle No.</span>
                  <span>Buyer's Name</span>
                  <span>City</span>
                </div>
                {currentItems.map((item, index) => (
                  <div key={index} className={styles.tableRow}>
                    <span>{item.bottleId}</span>
                    <span>{item.name}</span>
                    <span>{item.address.city}</span>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className={styles.pagination}>
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={styles.navigationButton}
                >
                  &lt;
                </button>
                {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
                  const pageNumber = currentPage + i - 1;
                  if (pageNumber >= 1 && pageNumber <= totalPages) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={currentPage === pageNumber ? styles.activePage : ''}
                      >
                        {pageNumber}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={styles.navigationButton}
                >
                  &gt;
                </button>
              </div>

              <div className={styles.backLink}>
                <a
                  href="#recentOrders"
                  onClick={showFullAmritJal ? toggleAmritJalList : toggleRoseFragranceList}
                >
                  Back to Recent Orders
                </a>
              </div>
            </div>
          ) : (
            /* Recent Orders Section */
            <div className={styles.recentOrdersSection}>
              <h2 className={styles.sectionTitle}>Recent Orders</h2>

              {/* Amrit Jal Table */}
              <div className={styles.productSection}>
                <h3 className={styles.productTitle}>
                  Amrit Jal
                  <span className={styles.stockInfo}>(8000 Limited Stock)</span>
                </h3>

                <div className={styles.ledgerTable}>
                  <div className={styles.tableHeader}>
                    <span>Unique Bottle No.</span>
                    <span>Buyer's Name</span>
                    <span>City</span>
                  </div>
                  {amritjalList.slice(0, 5).map((item, index) => (
                    <div key={index} className={styles.tableRow}>
                      <span>{item.bottleId}</span>
                      <span>{item.name}</span>
                      <span>{item.address.city}</span>
                    </div>
                  ))}
                </div>
                <div className={styles.seeMoreLink}>
                  <a href="#completeList" onClick={toggleAmritJalList}>See Complete List</a>
                </div>
              </div>

              {/* Amrit Jal Rose Fragrance Table */}
              <div className={styles.productSection}>
                <h3 className={styles.productTitle}>
                  Amrit Jal Rose Fragrance
                  <span className={styles.stockInfo}>(8000 Limited Stock)</span>
                </h3>

                <div className={styles.ledgerTable}>
                  <div className={styles.tableHeader}>
                    <span>Unique Bottle No.</span>
                    <span>Buyer's Name</span>
                    <span>City</span>
                  </div>
                  {rosejalList.slice(0, 5).map((item, index) => (
                    <div key={index} className={styles.tableRow}>
                      <span>{item.bottleId}</span>
                      <span>{item.name}</span>
                      <span>{item.address.city}</span>
                    </div>
                  ))}
                </div>
                <div className={styles.seeMoreLink}>
                  <a href="#completeList" onClick={toggleRoseFragranceList}>See Complete List</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <InfluencerFooter />
    </>
  );
};

export default LedgerPage;
