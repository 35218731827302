import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "../styles/components/Navbar.module.css";
import logo from "../assets/logo.png";
import logoIcon from "../assets/logo_icon.png";

const NavBar = ({ bookPujaRef }) => {
  const [phone, setPhone] = useState(null);
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [navLangText, setNavLangText] = useState("हिन्दी")

  useEffect(() => {
    const phone = JSON.parse(localStorage.getItem("phoneNumber"));
    if (phone) {
      setPhone(phone);
    }
    const language = JSON.parse(localStorage.getItem("language"));
    if(!language) {
      localStorage.setItem("language", JSON.stringify("en"));
      setLanguage("en");
    }else {
      setLanguage(language);
      toggleLangText();
    }
  }, [language]);

  const toggleLangText = () => {
    const navLang = JSON.parse(localStorage.getItem("language"));
    if (navLang === "en")
    setNavLangText("हिन्दी");
    else if (navLang === "hi")
    setNavLangText("English");
  }

  const handlePujaClick = () => {
    navigate("/");
    bookPujaRef?.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "hi" : "en";
    localStorage.setItem("language", JSON.stringify(newLanguage));
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div>
      <div className={styles.navigation}>
        <a href="/">
          <img src={logoIcon} alt="Dharmik App online puja prasad Ayodhya" className={styles.logo} />
          <img src={logo} alt="Dharmik App online puja prasad Ayodhya" className={styles.logo} />
          
        </a>
        <div className={styles.buttons}>
          <div className={styles.nav_links}>
            <b onClick={handlePujaClick} className={styles.navbar_pujas}>{t("Pujas")}</b>
            <a href="https://blogs.dharmikapp.in" className={styles.navbar_pujas}>{t("Blogs")}</a>
          
            <b className={styles.download_button} onClick={toggleLanguage}>
              {navLangText}
            </b>
            <b
              className={styles.download_button}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=in.gharkamandir.gharkamandir&pli=1"
                )
              }
            >
              {t("Download App")}
            </b>
            {phone ? (
              <b onClick={() => navigate("/profile")}className={styles.logedin_text}>{t("profile")}</b>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className={styles.login_button}
              >
                {t("Login")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
