
import React from 'react'
import styles from "../../styles/screens/Amritjal_hp/AmritjalAbout.module.css"
const AmritjalAbout = () => {
  return (
    <section className={styles.aboutSection}>
    <div className={styles.container}>
      <h1 className={styles.heading}>ABOUT DHARMIK</h1>

      <div className={styles.contentWrapper}>
        <p className={styles.paragraph}>
          In a fast-paced modern world, youth often struggle to find balance between tradition and modernism. Dharmik
          was born out of the need to bridge this gap—bringing spirituality to the fingertips of a tech-savvy
          generation. By blending ancient wisdom with a modern approach
        </p>

        <div className={styles.divider}></div>

        <p className={styles.paragraph}>
          Dharmik offers a space where young minds can reconnect with inner peace, explore their cultural roots, and
          celebrate faith in a way that resonates with their lifestyle. It's not just about rituals; it's about
          rediscovering purpose, harmony, and growth. Dharmik is where tradition meets the future, crafted especially
          for today's youth.
        </p>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <h3>Rooted in Ancient Wisdom</h3>
        </div>
        <div className={styles.card}>
          <h3>Inclusive and Youth-Focused</h3>
        </div>
        <div className={styles.card}>
          <h3>Reconnect with Inner Peace</h3>
        </div>
        <div className={styles.card}>
          <h3>Celebrating Culture and Faith</h3>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AmritjalAbout
