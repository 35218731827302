import React from "react";
import styles from "../../../styles/screens/AmritjalProductDetail/AmritjalSpray/ProductLandingSpray.module.css";
import { useNavigate } from "react-router-dom";
import amritjalRose from "../../../assets/amritjal_hp/amritjal-rose.png";
import amritjalClassic from "../../../assets/amritjal_hp/spray.png";
import mandala from "../../../assets/amritjal_hp/flower.png";
import rosePetals from "../../../assets/amritjal_hp/rose-petals.png";
const ProductLandingSpray = ({ productId }) => {
  const navigate = useNavigate();

  const handleOrderNowClick = () => {
    navigate(`/dharmikx/checkout/${productId}`);
  };

  return (
    <div 
    className={`${styles.container} ${productId === "amritjal-rose" ? styles.roseBackground : ""}`}
    style={productId === "amritjal-rose" ? { 
      backgroundImage: `url(${rosePetals})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    } : null}
  >
      {/* First Row - Hero Section */}
      <div className={styles.heroSection}>
        <h1>Instant Calm and Positivity</h1>
        <p>
          Elevate your mood and reduce stress with a refreshing spiritual mist anytime,
          anywhere.
        </p>
      </div>

      {/* Second Row - Product Section */}
      <div className={styles.productSection}>
        <div className={styles.productContent}>
        {productId === "amritjal-rose" && (
              <p className={styles.roseFragrance}>Infused with Rose Fragrance</p>
            )}
          <img 
            src={productId === "amritjal-rose" ? amritjalRose : amritjalClassic}
            alt="Product"
            className={styles.productImage}
            style={productId !== "amritjal-rose" ? { width: "500px" } : {}}
          />
          <div className={styles.productInfo}>
            <h2>Energize Your Daily Routine</h2>
            <p>
              Infused with sacred ingredients, it revitalizes your energy and keeps you
              focused.
            </p>
            <button onClick={handleOrderNowClick} className={styles.orderButton}>
              ORDER NOW
            </button>
            
          </div>
          
        </div>
      </div>

      {/* Third Row - Tradition Section */}
      <div className={styles.traditionSection}>
        <div className={styles.traditionContent}>
          <h2>Stay Connected to Tradition</h2>
          <p>
            A modern way for youth to embrace spiritual purity and cultural roots on the go.
          </p>
        </div>
        {productId === "amritjal-spray" && (
          <img src={mandala} alt="Mandala" className={styles.mandalaImage} />
        )}
      </div>
    </div>
  );
};

export default ProductLandingSpray;