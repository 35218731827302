import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Profile/SavedAddress.module.css";
import firebaseConfig from "../../firebase";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

const SavedAddress = () => {
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const [addressData, setAddressData] = useState();
  const [fetched, setFetched] = useState(false);
  const [showLeftContainer, setShowLeftContainer] = useState(false);
  console.log(addressData)

  const [newAddress, setNewAddress] = useState({
    name: "",
    phone: "",
    address: "",
    state: "",
    pincode: "",
    email: "",
    city: "",
  });

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const savedAddressRef = doc(db, "saved_address", phoneNumber);
        const querySnapshot = await getDoc(savedAddressRef);
        setAddressData(querySnapshot.data());
        if (querySnapshot.data()) setFetched(true);
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    fetchAddress();
  }, [db, phoneNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const saveNewAddress = async () => {
    try {
      const addressRef = doc(db, "saved_address", phoneNumber);
      const updatedData = { ...addressData, [newAddress.name]: newAddress };
      await setDoc(addressRef, updatedData);
      setAddressData(updatedData);
      setNewAddress({
        name: "",
        phone: "",
        address: "",
        state: "",
        pincode: "",
        email: "",
        city: "",
      });
      setShowLeftContainer(false); // Hide the left container after saving
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  const removeAddress = async (addressName) => {
    const addressRef = doc(db, "saved_address", phoneNumber);
    let updatedAddressData = { ...addressData };
    delete updatedAddressData[addressName];
    try {
      await setDoc(addressRef, updatedAddressData);
      setAddressData(updatedAddressData);
    } catch (error) {
      console.error("Error removing address:", error);
    }
  };

  return (
    <div className={styles.container}>
      {showLeftContainer && (
        <div className={styles.leftContainer}>
          <h1>{t("Address")}</h1>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="name"
              placeholder={t("Name")}
              value={newAddress.name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phone"
              placeholder={t("Phone")}
              value={newAddress.phone}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="address"
              placeholder={t("Address")}
              value={newAddress.address}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="state"
              placeholder={t("State")}
              value={newAddress.state}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="pincode"
              placeholder={t("Pincode")}
              value={newAddress.pincode}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder={t("Email")}
              value={newAddress.email}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder={t("City")}
              value={newAddress.city}
              onChange={handleInputChange}
            />
            <button onClick={saveNewAddress} className={styles.saveButton}>
              {t("Save Address")}
            </button>
          </div>
        </div>
      )}
      <div className={styles.verticalLine}></div>

      <div className={styles.rightContainer}>
        <h1>{t("Shipping Address")}</h1>
        <div className={styles.savedAddresses}>
          {fetched &&
            Object.entries(addressData || {}).map(([key, address]) => (
              <div key={key} className={styles.addressCard}>
                <span>{address.addressName}</span>
                <div className={styles.addressDetails}>
                <span>{address.house}</span><br/>
                  <span>{address.locality}</span><br/>
                  <span>{address.city}</span><br/>
                  <span>{address.pincode}</span>
                </div>
                <button
                  onClick={() => removeAddress(key)}
                  className={styles.removeButton}
                >
                  {t("Remove Address")}
                </button>
              </div>
            ))}
        </div>
        <button
          onClick={() => setShowLeftContainer(true)}
          className={styles.addButton}
        >
          {t("Add New Address")}
        </button>
      </div>
    </div>
  );
};

export default SavedAddress;