// Import necessary Firebase functions
import { doc, getDoc, arrayUnion, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firestoredb"; // Import Firestore initialization

async function assignBottlesAndUpdateAll(productId, orderId, quantity = 1, name, phone, city, pincode, state, coupon = "", price) {
  const productStockCollection = "dharmikxproductstock";
  const ledgerCollection = "dharmikxproductledger";
  const productStockRef = doc(db, productStockCollection, productId);
  const ledgerRef = doc(db, ledgerCollection, productId);

  const orderRef = doc(db, "orders", orderId);
  const orderHistoryRef = doc(db, "order_history", phone);
  const dharmikxOrderRef = doc(db, "dharmikxorders", orderId);

  try {
    // Step 1: Fetch the current bottle count from the product stock
    const productStockSnap = await getDoc(productStockRef);
    if (!productStockSnap.exists()) {
      throw new Error("No such product document exists in product stock!");
    }

    const currentBottle = productStockSnap.data().currentbottle || 5001;

    // Step 2: Generate the assigned bottle IDs
    const assignedBottleIds = [];
    for (let i = 0; i < quantity; i++) {
      assignedBottleIds.push(currentBottle + i + 1); // Generate IDs starting from the next available bottle
    }

    // Step 3: Update the current bottle count in the product stock
    await setDoc(
      productStockRef,
      {
        currentbottle: currentBottle + quantity, // Update the current bottle count
      },
      { merge: true }
    );

    // Step 4: Use a concrete timestamp (e.g., `new Date()`)
    const timestamp = new Date(); // Use a client-side timestamp for now

    // Step 5: Prepare the ledger entries for each assigned bottle
    const ledgerEntries = assignedBottleIds.map((bottleId) => ({
      address: {
        city,
        pincode,
        state,
      },
      bottleId: bottleId.toString(),
      coupon,
      name,
      orderId,
      phone,
      price,
      productId,
      timestamp, // Use the resolved timestamp
    }));

    // Step 6: Update the ledger with the new entries
    await updateDoc(ledgerRef, {
      ledger: arrayUnion(...ledgerEntries), // Append new entries to the ledger array
    });

    if(coupon !== "") {
      const collabRef = doc(db, "dharmikxinfluencerledger", coupon);
      await updateDoc(collabRef,{
        ledger: arrayUnion(...ledgerEntries),
      });
    }

    // Step 7: Update the order-related documents with the assigned bottle IDs
    const bottleIdUpdate = { bottleId: assignedBottleIds };

    // Update "orders" document
    const orderSnap = await getDoc(orderRef);
    const orderData = orderSnap.data();
    if (orderSnap.exists()) {
      await setDoc(
        orderRef,
        {
          [orderId]: {
            ...orderData[orderId],
            ...bottleIdUpdate,
            updated_time: serverTimestamp(),
          },
        },
        { merge: true }
      );
    }

    // Update "order_history" document
    const orderHistorySnap = await getDoc(orderHistoryRef);
    const orderHistoryData = orderHistorySnap.data();
    if (orderHistorySnap.exists()) {
      await setDoc(
        orderHistoryRef,
        {
          [orderId]: {
            ...orderHistoryData[orderId],
            ...bottleIdUpdate,
            updated_time: serverTimestamp(),
          },
        },
        { merge: true }
      );
    }

    // Update "dharmikxorders" document
    const dharmikxOrderSnap = await getDoc(dharmikxOrderRef);
    const dharmikxOrderData = dharmikxOrderSnap.data();
    if (dharmikxOrderSnap.exists()) {
      await setDoc(
        dharmikxOrderRef,
        {
          [orderId]: {
            ...dharmikxOrderData[orderId],
            ...bottleIdUpdate,
            updated_time: serverTimestamp(),
          },
        },
        { merge: true }
      );
    }

    console.log("Bottle IDs successfully assigned, ledger updated, and order documents updated:", assignedBottleIds);
    return assignedBottleIds;
  } catch (error) {
    console.error("Error assigning bottles and updating all documents:", error);
    throw error;
  }
}

export default assignBottlesAndUpdateAll;