import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/screens/Influencer/components/LoginPage.module.css";
import PasswordUtil from "../../utils/PasswordUtil";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firestoredb";

const LoginPage = () => {
  const navigate = useNavigate();
  const [isValidating, setIsValidating] = useState(false);

  // Check if the user is already logged in
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/dharmikx/influencer/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const userId = e.target.userId.value;
    const password = e.target.password.value;
    if(userId && password) {
      setIsValidating(true);
      const hashedPasskey = PasswordUtil.hashPassword(password);

      const userDocRef = doc(db, "dharmikxcoupons", userId);
      const userSnap = await getDoc(userDocRef);

      if(userSnap.exists()) {
        const userData = userSnap.data();
        const serverHashedPasskey = userData.passkey;
        const phone = userData.phone;
        if(hashedPasskey === serverHashedPasskey) {
          localStorage.setItem("influencerUserName", JSON.stringify(userId.toString()));
          localStorage.setItem("influencerPhoneNumber", JSON.stringify(phone.toString()));
          navigate("/dharmikx/influencer/dashboard");
          setIsValidating(false);
        }
      } else {
        alert("No Suich User Exist !");
      }
    }
    setIsValidating(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h1 className={styles.logo}>DHARMIK</h1>
        <p className={styles.welcomeText}>
          Welcome to the <span className={styles.highlight}>Dharmik X Influencer</span> Program. Unlock an exciting
          journey where spirituality meets modern influence! As a Dharmik influencer, you get to inspire others, share
          in our success, and spread the light of spirituality in a modern way. Here's what's in store for you:
        </p>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.loginForm}>
          <h2 className={styles.connectHeading}>CONNECT NOW</h2>
          <form onSubmit={handleLogin}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                name="userId"
                placeholder="User Id"
                className={styles.input}
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className={styles.input}
              />
            </div>
            <div className={styles.buttondiv}>
              <button type="submit" className={styles.loginButton}>
                {isValidating ? "Validating..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
