import React from 'react';
import styles from '../../../styles/screens/AmritjalProductDetail/VrindavanPrasad/PrasadGift.module.css';
import sweetBox from "../../../assets/holiprasad/db.png";
import PrasadProduct from './PrasadProduct';
import bg from "../../../assets/holiprasad/vrindavan_thumbnail.jpg"; 
import ReactPlayer from 'react-player';

const PrasadGift = () => {
  return (
    <div className={styles.productContainer}>
      <div className={styles.productContent}>
        <div className={styles.productImageContainer}>
          <img
            src={sweetBox}
            alt="Dharmik product box with decorative patterns"
            className={styles.productImage}
          />
        </div>
        <div className={styles.productTextContainer}>
          <div className={styles.giftText}>
            <span className={styles.giftTitle}>A Gift Straight From</span>
            <div className={styles.mainText}>
              <div className={styles.dividerLine}></div>
              <h1 className={styles.vrindavanText}>Vrindavan</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Video for Small Devices */}
      <div className={styles.videoContainer}>
        <ReactPlayer
          url="https://d3vqmpzcil03dt.cloudfront.net/reels/vrindavan_reel.mp4"
          light={bg}
          playing
          controls
          width="100%"
          height="100%"
          className={styles.reactPlayer}
          onBuffer={() => console.log("Buffering...")}
  onBufferEnd={() => console.log("Buffering ended")}
        />
      </div>

      {/* Grid View */}
      <PrasadProduct />
    </div>
  );
};

export default PrasadGift;
