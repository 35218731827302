// Import necessary Firestore functions
import { getFirestore, doc, getDoc } from "firebase/firestore";

// Firestore utility function to fetch data
async function fetchAmritJalProductStock(productId) {
    const collectionName = "dharmikxproductstock";
  try {
    const db = getFirestore();
    const docRef = doc(db, collectionName, productId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
}

export default fetchAmritJalProductStock;