class PasswordUtil {
  /**
   * Hash a password using a fixed salt (for testing purposes).
   * @param {string} password - The plain text password to be hashed.
   * @returns {string} The hashed password in the format: salt:hash
   */
  static hashPassword(password) {
    const salt = 'dharmikxInfluencercollabAmritJal9910587459'; // Use a fixed salt for consistent results
    const hash = PasswordUtil.simpleHash(password + salt); // Hash the password with the salt
    return `${salt}:${hash}`; // Return salt and hash in a single string
  }

  /**
   * Verify if a given password matches the stored hash.
   * @param {string} password - The plain text password to verify.
   * @param {string} storedHash - The stored hash in the format: salt:hash
   * @returns {boolean} Whether the password is valid or not.
   */
  static verifyPassword(password, storedHash) {
    const [salt, originalHash] = storedHash.split(':'); // Split the stored hash into salt and hash
    const hash = PasswordUtil.simpleHash(password + salt); // Hash the input password with the same salt
    return hash === originalHash; // Compare the hashes
  }

  /**
   * A simple (insecure) hash function.
   * @param {string} input - The input string to hash.
   * @returns {string} A hashed version of the input.
   */
  static simpleHash(input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const charCode = input.charCodeAt(i);
      hash = (hash << 5) - hash + charCode; // Perform bitwise operations
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash).toString(16); // Convert to a positive hexadecimal string
  }
}

export default PasswordUtil;