import React, { useEffect } from "react";
import styles from "../../styles/screens/HiddenGemGateway/HiddenGemGateway.module.css";
import { usePostHog } from "posthog-js/react";
import WhatsAppSupport from "../../components/Support/WhatsAppSupport";

const HiddenGemGateway = () => {
  const posthog = usePostHog();
  
  useEffect(() => {
    // Dynamically load the Tally embed script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Handle dynamic loading of Tally embeds
    script.onload = () => {
      const tryLoadTally = () => {
        if (window.Tally) {
          window.Tally.loadEmbeds(); // Call the loadEmbeds method
        } else {
          setTimeout(tryLoadTally, 100); // Retry after 100ms if Tally is not yet available
        }
      };
      tryLoadTally();
    };

    return () => {
      // Cleanup the script on component unmount
      document.body.removeChild(script);
    };
  }, []);

    useEffect(() => {
        posthog.capture("$pageview");
      }, [posthog]);
  
  

  return (
    <div className={styles.gateway_container}>
      <header className={styles.gateway_header}>
        <h1 className={styles.gateway_title}>Hidden Gem Gateway</h1>
        <p className={styles.gateway_description}>
          Welcome to Hidden Gem Gateway! Fill out the form below to plan your perfect weekend getaway.
        </p>
      </header>
      <WhatsAppSupport/>

      <div className={styles.form_container}>
        {/* Embed your Tally form */}
        <iframe
          data-tally-src="https://tally.so/embed/n9oMYY?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="400"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Hidden Gem weekend gateway"
        ></iframe>
      </div>

      <footer className={styles.gateway_footer}>
        <p>© 2025 Hidden Gem Gateway. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HiddenGemGateway;