import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Profile/Login.module.css";
import Footer from "../../components/Footer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import loginIcon from "../../assets/login/hand.png";
import generateReferralCode from "../../utils/Referral";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { usePostHog } from 'posthog-js/react'

const auth = getAuth();
auth.useDeviceLanguage();

const db = getFirestore();

const Login = () => {
  const { parentReferralId } = useParams() || "";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("Recaptcha resolved.");
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render error: ", error);
      });
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error(t("Please enter correct mobile number"));
      return;
    }

    setUpRecaptcha();
    const phoneNumberWithCountryCode = phoneNumber;
    const appVerifier = window.recaptchaVerifier;

    // Show loading toast
    const loadingToastId = toast.loading(t("Sending OTP..."));

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberWithCountryCode,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
      
      // Dismiss loading toast and show success
      toast.dismiss(loadingToastId);
      toast.success(t("OTP sent successfully"), { autoClose: 2000 });
    } catch (error) {
      // Dismiss loading toast and show error
      toast.dismiss(loadingToastId);
      toast.error(t("Failed to send OTP. Please try again."));
      console.error("Error sending OTP: ", error.message);
      
      // Reset recaptcha on error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!verificationId || !otp) {
      toast.error(t("Please enter the OTP"));
      return;
    }

    // Show loading toast
    const loadingToastId = toast.loading(t("Verifying OTP..."));

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);

      const userRef = doc(db, "users", phoneNumber);
      const userSnapshot = await getDoc(userRef);

      // Dismiss loading toast
      toast.dismiss(loadingToastId);
      toast.success(t("Sign in Successful"), { autoClose: 2000 });

      posthog.identify(phoneNumber, {
        phone : phoneNumber,
        platform : "web",
        category : "web_user",
      });

      posthog.people.set({
        phone : phoneNumber,
        platform : "web",
        category : "web_user",
      });

      if (userSnapshot.exists()) {
        await updateDoc(userRef, {
          last_login: serverTimestamp(),
        });
      } else {
        await setDoc(userRef, {
          phoneNumber: phoneNumber,
          platform: "web",
          userType: "general",
          invitedBy: parentReferralId || "",
          referral_code: generateReferralCode(phoneNumber),
          time: serverTimestamp(),
          last_login: serverTimestamp(),
        });

        const referredRef = doc(
          db,
          "referral_db",
          generateReferralCode(phoneNumber)
        );
        const referredSnapshot = await getDoc(referredRef);

        if (!referredSnapshot.exists()) {
          await setDoc(referredRef, {
            createdAt: serverTimestamp(),
          });
        }

        if (parentReferralId) {
          var reff;
          if (parentReferralId.length === 7) reff = "merchant_referral_db";
          else reff = "referral_db";

          const referredRef = doc(db, reff, parentReferralId);
          const referredSnapshot = await getDoc(referredRef);

          if (referredSnapshot.exists()) {
            const referredUserData =
              referredSnapshot.data().referred_users || [];
            await updateDoc(referredRef, {
              web_signup_count: referredSnapshot.data().web_signup_count + 1,
              referred_users: [
                ...referredUserData,
                {
                  phonenumber: phoneNumber,
                  platform: "web",
                  userType: "general",
                  timestamp: new Date(),
                },
              ],
            });
          } else {
            await setDoc(referredRef, {
              web_signup_count: 1,
              referred_users: [
                {
                  phonenumber: phoneNumber,
                  platform: "web",
                  userType: "general",
                  timestamp: new Date(),
                },
              ],
            });
          }
        }

        posthog.capture('user_signed_up', {
          phone: phoneNumber,
          platform: "web",
          category: "web_user",
        });
      }

      localStorage.setItem("phoneNumber", JSON.stringify(phoneNumber));
      localStorage.setItem(
        "referral_code",
        JSON.stringify(generateReferralCode(phoneNumber))
      );
      localStorage.setItem("userType", "general");
      localStorage.setItem("invitedBy", parentReferralId);

      posthog.capture('user_logged_in', {
        phone: phoneNumber,
        platform: "web",
        category: "web_user",
      });

      navigate("/profile");
    } catch (error) {
      // Dismiss loading toast and show error
      toast.dismiss(loadingToastId);
      toast.error(t("Invalid OTP. Please try again."));
      console.error("Error verifying OTP: ", error.message);
      posthog.capture('login_error', {
        error_message: error.message,
        phone: phoneNumber
      });
    }
  };

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        style={{ marginTop: '60px' }}
        limit={1}
      />
      <div className={styles.mainArea}>
        <main className={styles.mainContent}>
          <section className={styles.section}>
            <img src={loginIcon} alt="Puja" className={styles.loginImage} />
            <h1 className={styles.title}>{t("Online Blessing")}</h1>
            {/* <h3>
              {t(
                "Receive blessings through pooja  online at your religious temple."
              )}
            </h3>
            <img src={moveIcon} alt="Puja" className={styles.loginImage} /> */}
          </section>
          <aside className={styles.listing}>
            {/* <img src={starIcon} alt="Puja" className={styles.loginImage} /> */}
            {!verificationId && (
              <form onSubmit={handleSendOtp} className={styles.authForm}>
                <h2>{t("Get started")}</h2>
                <h3>{t("Enter your Mobile no. to book your first Puja")}</h3>
                <PhoneInput
                  placeholder={t("Please enter your phone number")}
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  required
                  className={styles.phoneInput}
                />
                <div className={styles.permissionContainer}>
                  <label className={styles.permissionItem}>
                    <input type="checkbox" />
                    I agree to receive communication from Dharmik regarding newsletters, promotional content, updates, offers, and events via SMS, RCS, WhatsApp, and email.
                  </label>

                  <label className={styles.permissionItem}>
                    <input type="checkbox" />
                    I agree to the{" "}
                    <a
                      href="https://dharmikapp.in/terms-and-conditions" // Replace with actual link
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Terms and Conditions
                    </a>{" "}
                    and confirm that all the information provided is accurate and true.
                  </label>

                  <p className={styles.privacyText}>
                    I acknowledge that my information will be processed in accordance with Dharmik's{" "}
                    <a
                      href="https://dharmikapp.in/privacy-policy" // Replace with actual link
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Privacy Policy
                    </a>{" "}
                    and will not be shared with unauthorized third parties.
                  </p>
                </div>
                
                <button type="submit" className={styles.submitButton}>
                  {t("Send OTP")}
                </button>
              </form>
            )}
            {verificationId && (
              <form className={styles.authForm}>
                <h2>{t("Welcome to Dharmik")}</h2>
                <input
                  placeholder={t("Enter OTP")}
                  type="tel"
                  id="otp"
                  name="otp"
                  value={otp}
                  className={styles.authInput}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button
                  onClick={handleVerifyOtp}
                  className={styles.submitButton}
                >
                  {t("Verify OTP")}
                </button>
              </form>
            )}
            <div id="recaptcha-container"></div>
          </aside>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
