import { Link } from "react-router-dom";
import styles from "../../styles/screens/Amritjal_hp/AmritjalFeature.module.css";

const AmritjalFeature = ({ bg, icon, title, content, link, subtitle }) => {
  return (
    <>
    <Link to={link}>
      <div className={styles.BannerContainer}>
        <div className={styles.ContentSection}>
          <div className={styles.ProductSection}>
            <img src={icon} alt={title} className={styles.ProductImage} />
            <div className={styles.ProductInfo}>
              <h1 className={styles.ProductTitle}>{title}</h1>
              {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
              <p
  className={`${styles.ProductDescription} ${
    title === 'The Soul Drifters:Journeys to Recharge & Explore' ? styles.LimitedWidth : ''
  }`}
>
  {content}
</p>
              <button className={styles.ShopNowButton}>Shop Now</button>
            </div>
          </div>
        </div>
        <div
          className={styles.WaterBackground}
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
      </div>
    </Link>
    </>
  );
};

export default AmritjalFeature;
