import React, {useEffect} from 'react'
import LoginPage from '../../components/InflunencerHomepage/LoginPage'
import CardSection from '../../components/InflunencerHomepage/CardSection'
import HeroSection from '../../components/InflunencerHomepage/HeroSection'
import InfluencerHeader from '../../components/InflunencerHomepage/InfluencerHeader'
import InfluencerFooter from '../../components/InflunencerHomepage/InfluencerFooter'
import { usePostHog } from "posthog-js/react"
import WhatsAppSupport from '../../components/Support/WhatsAppSupport'
const InfluencerHomePage = () => {
  const posthog = usePostHog();
    useEffect(() => {
        posthog.capture("$pageview");
      }, [posthog]);
      
  return (
    <>
    <InfluencerHeader/>
    <WhatsAppSupport/>
    <HeroSection/>
    <LoginPage/>
    <CardSection/>
    <InfluencerFooter/>
    </>
  )
}

export default InfluencerHomePage
