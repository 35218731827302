async function getDharmikxOrderId(pujaPrice, currency) {
    const tmp = Math.round(pujaPrice).toString();
    const response = await fetch("https://us-central1-ghar-ka-mandir.cloudfunctions.net/getAmritJalOrderId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: tmp * 100, currency: currency }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error generating Order ID: ${errorData.error.description}`);
    }
    const data = await response.json();
    console.log('DHARMIKX', data);
    return data.id;
  }
  
  export default getDharmikxOrderId;