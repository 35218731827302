import React, { useState, useEffect, useRef } from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import firebaseConfig from "../firebase"; import moment from "moment"; import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc, setDoc, addDoc, query, where} from "firebase/firestore";
import "../styles/screens/Dashboard.module.css";
//import closeIcon from "../assets/close_icon.png";
import filterIcon from "../assets/filter.png";
import CSVParser from "papaparse";
import getOrderId from "../utils/OrderId";

const Dashboard = () => 
{
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [loadingCoupons, setLoadingCoupons] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState("orders");
  const [waitlist, setWaitlist] = useState([]);
  const [loadingWaitlist, setLoadingWaitlist] = useState(true);
  const [payments, setPayments] = useState([]);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showCouponDialog, setShowCouponDialog] = useState(false);
  const [showUserDialog, setShowUserDialog] = useState(false);
  const [maximum, setMaximum] = useState("");
  const [coupon_name, setCoupon_name] = useState("");
  const [percentage, setPercentage] = useState("");
  const [operational, setOperational] = useState("");
  //const [remark, setRemark] = useState("");
  const fileInputRef = useRef(null);
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const navigate = useNavigate();
  const userPhoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const isLoggedIn = userPhoneNumber ? true : false;
  const [showFilter1, setShowFilter1] = useState(false);
  const [platform, setPlatform] = useState("");
  const [userType, setuserType] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterValue1, setFilterValue1] = useState("");
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleCouponButtonClick = () => {
    setShowCouponDialog(true);
  };

  const handleCSVImportOrders = async(event) => {
      const file = event.target.files[0];
    if (file) {
      CSVParser.parse(file, {
          header: true,
          complete: async (results) => {
              const newOrderData = results.data;
              //console.log('newOrderData', newOrderData);
  
              if (newOrderData.length === 0) {
                  console.error("CSV file is empty.");
                  return;
              }
  
              const headers = Object.keys(newOrderData[0]);
  
              for (let i = 0; i < newOrderData.length; i++) {
                  const dataItem = newOrderData[i];
                  Object.keys(dataItem).forEach(key => {
                      //console.log(`${key}: ${dataItem[key]}`);
                  });
  
                  const userPhoneNumber = dataItem[headers.find(header => header.toLowerCase().includes("phone"))];
                  if (!userPhoneNumber) {
                      console.error('UserPhoneNumber is missing in order details.');
                      continue;
                  }
                  const usersQuery = query(collection(db, 'users'), where('phoneNumber', '==', userPhoneNumber));
                  const usersQuerySnapshot = await getDocs(usersQuery);
  
                  let userId;
                  if (usersQuerySnapshot.empty) {
                      const newUserRef = await addDoc(collection(db, 'users'), {
                          phoneNumber: userPhoneNumber,
                      });
                      userId = newUserRef.id;
                      //console.log('New user created with ID:', userId);
                  } else {
                      usersQuerySnapshot.forEach(doc => {
                          userId = doc.id;
                      });
                      //console.log('User exists with ID:', userId);
  
                      const userOrdersRef = doc(db, 'users', userId);
                      const userOrdersDoc = await getDoc(userOrdersRef);
                      if (userOrdersDoc.exists()) {
                          const userOrderHistory = userOrdersDoc.data().orders || [];
                          const orderId = dataItem[headers.find(header => header.toLowerCase().includes("order"))];
                          if (userOrderHistory.includes(orderId)) {
                              //console.log('Order ID already exists in user order history. Skipping order.');
                              continue;
                          }
                      }
                  }
                  const priceKey = headers.find(header => header.toLowerCase().includes("price"));
                  const newOrderId = await getOrderId(dataItem[priceKey]);
  
                  // Create address map
                  const addressMap = {};
                  headers.forEach(header => {
                      if (header.toLowerCase().includes("city") || header.toLowerCase().includes("state") || header.toLowerCase().includes("pincode") || header.toLowerCase().includes("address")) {
                          addressMap[header] = dataItem[header];
                      }
                  });
                  //console.log('Address Map:', addressMap);
  
                  // Remove address properties from dataItem
                  const addressProperties = Object.keys(addressMap);
                  addressProperties.forEach(property => {
                      delete dataItem[property];
                  });
  
                  // Create order map
                  const orderMap = {};
                  orderMap[newOrderId] = dataItem;
                  //console.log('Order Details:', orderMap);
  
                  // Combine order map and address map
                  const combinedMap = {
                      ...orderMap,
                      [newOrderId]: {
                          ...orderMap[newOrderId],
                          Address: addressMap
                      }
                  };
                  //console.log('Combined Map:', combinedMap);
  
                  // Add the order to each desired collection
                  const collections = ['importcodordertest', 'importorderhistorytest', 'importorderstest', 'importsuccessordertest'];
                  for (const collectionName of collections) {
                      const addToFirestore = async (orderID, orderData) => {
                          try {
                              await setDoc(doc(db, collectionName, orderID), orderData);
                              //console.log('Order added to Firestore:', orderData);
                          } catch (error) {
                              console.error('Error adding order to Firestore:', error);
                          }
                      };
                      await addToFirestore(newOrderId, combinedMap[newOrderId]);
                  }
              }
          },
          error: (error) => {
              console.error("Error parsing CSV:", error);
          },
      });
  }
  
  
  
  };

  const handleCSVImportUsers = async (event) => {
    const file = event.target.files[0];
    if (file) {
        CSVParser.parse(file, {
            header: true,
            complete: async (results) => {
                const newUserData = results.data;
                if (newUserData.length === 0) {
                    console.error("CSV file is empty.");
                    return;
                }

                const headers = Object.keys(newUserData[0]);
                const phoneNumberKey = headers.find(header => header.toLowerCase().includes("phone"));
                const userNameKey = headers.find(header => header.toLowerCase().includes("name"));

                if (!phoneNumberKey || !userNameKey) {
                    console.error("Required columns (phone number and customer name) are missing.");
                    return;
                }

                for (const dataItem of newUserData) {
                    //console.log('newUserData', dataItem);

                    const userPhoneNumber = dataItem[phoneNumberKey];
                    if (!userPhoneNumber) {
                        console.error('Phone Number is missing in user details.');
                        continue;
                    }

                    const userName = dataItem[userNameKey];
                    if (!userName) {
                        console.error('Name is missing in user details.');
                        continue;
                    }

                    const userId = userPhoneNumber;
                    const userDetails = {
                        phoneNumber: userPhoneNumber,
                        userName: userName
                    };

                    for (const key in dataItem) {
                        if (key !== phoneNumberKey && key !== userNameKey) {
                            //console.log(`${key}: ${dataItem[key]}`);
                            userDetails[key] = dataItem[key];
                        }
                    }

                    //console.log('User Details:', userDetails);

                    await addUserToFirestore(userId, userDetails);
                }
            },
            error: (error) => {
                console.error("Error parsing CSV:", error);
            },
        });
    }
  };

  const addUserToFirestore = async (userId, userDetails) => {
      try {
          await setDoc(doc(db, 'importuserstest', userId), userDetails);
          console.log('User added to Firestore:', userDetails);
      } catch (error) {
          console.error('Error adding user to Firestore:', error);
      }
  };

  // const handleUpdateOrder= async () => {
  //   try {
  //     const app = initializeApp(firebaseConfig);
  //     const db = getFirestore(app);
  //     const orderRef = doc(db, "orders", selectedOrderDetails.orderId);
  //     const orderId = selectedOrderDetails.orderId;
  //     await setDoc(orderRef, {
  //       [orderId]: {
  //         ...selectedOrderDetails,
  //       },
  //     });
  //     setShowDialog(false);
  //     window.location.reload();

  //     console.log("Order updated successfully");
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleUpdateUser = async () => {
  //   try {
  //     const app = initializeApp(firebaseConfig);
  //     const db = getFirestore(app);
  //     const userRef = doc(db, "users", selectedUserDetails.phoneNumber);
    
  //     await setDoc(userRef,selectedUserDetails, { merge: true });
  //     setShowUserDialog(false);
  //     window.location.reload();

  //     console.log("User updated successfully");
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const generateCoupon = async () => {
    try {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const couponsCollectionRef = collection(db, "coupons");
      
      const newCouponData = {
        discount: {
          max: maximum,
          percentage: percentage,
        },
        operational: operational,
      };
      
      console.log(newCouponData);
      
      const couponDocRef = doc(couponsCollectionRef, coupon_name); 
      
      await setDoc(couponDocRef, newCouponData);
      setShowDialog(false);
      window.location.reload();
      setActiveTab("coupons");
      console.log("New coupon added successfully");
    } catch (error) {
      console.error("Error generating coupon data:", error);
    }
  };
    
  // const handleUserInputChange = (fieldName, value) => {
  //   setSelectedUserDetails((prevState) => ({
  //     ...prevState,
  //     [fieldName]: value,
  //   }));
  // };

  // const handleOrderInputChange = (fieldName, value) => {
  //   setSelectedOrderDetails((prevState) => ({
  //     ...prevState,
  //     [fieldName]: value,
  //   }));
  // };

  const handleFilterChange = async (event) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const data = await fetchData(db, "orders");
    const isFilterSelected = filterValue === event;
    console.log(filterValue);
    console.log(isFilterSelected);

    if (isFilterSelected) {
      setFilterValue("");
      event = "All";
    } else {  
      setFilterValue(event);
    }
    if (event === "All") {
      setOrders(data);
      return;
    }

    const filteredData = [];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      const orderKey = Object.keys(data[i])[0];
      if (
        data[i][orderKey].paymentMethod &&
        data[i][orderKey].paymentMethod.toUpperCase() === event.toUpperCase()
      ) {
        filteredData.push(data[i]);
      }
      if (
        data[i][orderKey].status &&
        data[i][orderKey].status.toUpperCase() === event.toUpperCase()
      ) {
        filteredData.push(data[i]);
      }
    }
    setOrders(filteredData);
  };

  const convertToCSV_users = (data) => {
    let keyset = [];
    for (let i = 0; i < data.length; i = i + 1) {
      var obj = Object.keys(data[i]);
      for (let j = 0; j < obj.length; j = j + 1) {
        if (!keyset.includes(obj[j])) {
          keyset.push(obj[j]);
        }
      }
    }

    let str = "";
    str = str + keyset.toString();
    let newRow = {};
    keyset.forEach((key) => (newRow[key] = ""));
    for (let i = 0; i < data.length; i = i + 1) {
      let tempRow = Object.assign({}, newRow);
      tempRow = { ...tempRow, ...data[i] };
      console.log("values", tempRow);
      if (tempRow.timestamp) {
        let time = tempRow.timestamp;
        time = 1000 * time.seconds + time.nanoseconds / 1e6;
        tempRow.timestamp = "" + time;
      }
      if (tempRow.time) {
        let time = tempRow.time;
        time = 1000 * time.seconds + time.nanoseconds / 1e6;
        tempRow.time = "" + time;
      }
      if (tempRow.last_login) {
        let time = tempRow.last_login;
        time = 1000 * time.seconds + time.nanoseconds / 1e6;
        tempRow.last_login = "" + time;
      }
      if (tempRow.updateTime) {
        let time = tempRow.updateTime;
        time = 1000 * time.seconds + time.nanoseconds / 1e6;
        tempRow.updateTime = "" + time;
      }
      let arr = Object.values(tempRow).toString();
      str = str + "\n" + arr;
    }
    
    return str;
  };
  const convertToCSV_orders = (data, keysToInclude, columnMapping) => {
    const columnNameMap = {};
    columnMapping.forEach(mapping => {
        if (!columnNameMap[mapping.key]) {
            columnNameMap[mapping.key] = [];
        }
        columnNameMap[mapping.key].push(mapping.name);
    });

    const updatedColumnNames = [];
    keysToInclude.forEach(key => {
        if (columnNameMap[key]) {
            updatedColumnNames.push(...columnNameMap[key]);
        }
    });

    const delhiveryColumns = [
      '*Pickup Location Name',
      '*Transport Mode',
      'Packaging Type',
      'Length (cm)',
      'Breadth (cm)',
      'Height (cm)',
      'Weight (gm)'
    ];
    updatedColumnNames.push(...delhiveryColumns);

    let str = updatedColumnNames.join(',') + '\n'; 
    for (let i = 0; i < data.length; i++) {
        const orderIds = Object.keys(data[i]);
        for (let j = 0; j < orderIds.length; j++) {
            const orderId = orderIds[j];
            const orderIdMap = data[i][orderId];
            let tempRow = {};

            keysToInclude.forEach(key => {
              if (columnNameMap[key]) {
                  columnNameMap[key].forEach((name, index) => {
                      let value = orderIdMap[key] || '';
                      if (key === 'paymentMethod') {
                          if (value.toLowerCase() === 'online') {
                              value = 'Prepaid';
                          } else if (value.toLowerCase() === 'cod') {
                              value = 'cod';
                          }
                      }
                      tempRow[`${key}_${index}`] = value;
                  });
              } else {
                  let value = orderIdMap[key] || '';
                  if (key === 'paymentMethod') {
                      if (value.toLowerCase() === 'online') {
                          value = 'Prepaid';
                      } else if (value.toLowerCase() === 'cod') {
                          value = 'cod';
                      }
                  }
                  tempRow[key] = value;
              }

              if (key === 'code' || key === 'name') {
                if (key === 'code') {
                    tempRow['ItemSKU'] = orderIdMap['skucode'] || '';
                } else if (key === 'name') {
                    tempRow['ItemSKU Name'] = orderIdMap['skuname'] || '';
                }
            }
          });

          tempRow['*Pickup Location Name'] = 'Dharmik Bharat';
          tempRow['*Transport Mode'] = 'Surface';
          tempRow['Packaging Type'] = 'Cardboard Box';
          tempRow['Length (cm)'] = '16';
          tempRow['Breadth (cm)'] = '15';
          tempRow['Height (cm)'] = '12';
          tempRow['Weight (gm)'] = '430';

          if (orderIdMap.address) {
            const addressMap = orderIdMap.address;
            // Initialize individual components with fallback to empty string if not present
            let house = addressMap['house'] || '';
            let locality = addressMap['locality'] || '';
            let addressString = `${house}${locality ? ' ' + locality : ''}`; // Concatenate with space only if locality is present
        
            // Set the full address string to the appropriate column(s)
            if (columnNameMap['house']) { // Assuming 'house' key is used for the Address column
                columnNameMap['house'].forEach((name, index) => {
                    tempRow[`${'house'}_${index}`] = addressString;
                });
            }
        }

            const rowValues = updatedColumnNames.map(columnName => {
              const originalKey = keysToInclude.find(key => columnNameMap[key] && columnNameMap[key].includes(columnName));
              const keyWithIndex = originalKey ? `${originalKey}_${columnNameMap[originalKey].indexOf(columnName)}` : columnName;
              
              if (columnName === 'COD Amount') {
                  const paymentMethod = tempRow['paymentMethod_0'];
                  if (paymentMethod === 'Prepaid') {
                      return '""'; 
                  }
              }

              return `"${tempRow[keyWithIndex] || ''}"`;
          });

            str += rowValues.join(',') + '\n';
        }
    }

    return str;
  };

  const keysToInclude = [
      'orderId', 'paymentMethod', 'price', 'name', 'phoneNumber',
      'house', 'city', 'state', 'pincode', 'skuname', 'skucode', 'quantity',
  ]; 
  const columnMapping = [
      { key: 'orderId', name: '*Sale Order Number' },
      { key: 'paymentMethod', name: '*Payment Mode' },
      { key: 'price', name: 'COD Amount' },
      { key: 'price', name: '*Unit Item Price' },
      { key: 'name', name: '*Customer Name' },
      { key: 'phoneNumber', name: '*Customer Phone' },
      { key: 'house', name: '*Shipping Address Line1' },
      { key: 'city', name: '*Shipping City' },
      { key: 'state', name: '*Shipping State' },
      { key: 'pincode', name: '*Shipping Pincode' },
      { key: 'skucode', name: '*Item Sku Code' },
      { key: 'skuname', name: '*Item Sku Name' },
      { key: 'quantity', name: '*Quantity Ordered' },
  ];
  const downloadCSV_users = (data) => {
    const csv = convertToCSV_users(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const timestamp = new Date().toISOString().replace(/[:\-T]/g, '').split('.')[0];
    const filename = `user_export_${timestamp}.csv`;

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  };
  const downloadCSV_orders = (data) => {
    const csvData = convertToCSV_orders(data, keysToInclude, columnMapping);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const timestamp = new Date().toISOString().replace(/[:\-T]/g, '').split('.')[0];
    const filename = `order_export_${timestamp}.csv`;

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  };

  const handleFilterChange1 = async (value) => {
    const isFilterSelected = filterValue === value;

    if (isFilterSelected) {
      // If the filter is already selected, remove it
      setFilterValue("");
    } else {
      // If the filter is not selected, add it
      setFilterValue(value);
    } 
    setPlatform(value);
  };
  const handleFilterChange2 = async (value) => {

    const isFilterSelected = filterValue1 === value;

    if (isFilterSelected) {
      setFilterValue1("");
    } else {
      setFilterValue1(value);
    }
    setuserType(value);
  };

  const fetchData = async (db, collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map((doc) => doc.data());
  };

  const handleDetailsClick = (orderDetails) => {
    setSelectedOrderDetails(orderDetails);
    setShowDialog(true);
  };
  
  const handleDetailsClick1 = (userDetails) => {
    setSelectedUserDetails(userDetails);
    setShowUserDialog(true);
  };

  useEffect(() => {
    const handleFilterChange3 = async () => {
      console.log(platform, userType);
      if (!platform || !userType) {
        return;
      }

      var data = [];
      if (userType === "Normal") {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "users"));
        data = querySnapshot.docs.map((doc) => doc.data());
      }
      if (userType === "Merchant") {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "merchants"));
        data = querySnapshot.docs.map((doc) => doc.data());
      }

      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].platform === platform) {
          filteredData.push(data[i]);
        }
      }
      setUsers(filteredData);
    };

    handleFilterChange3();
  }, [platform, userType]);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const userDoc = doc(db, "adminpanelusers", userPhoneNumber);
        const userSnapshot = await getDoc(userDoc);
        console.log(userSnapshot.data());
        if (userSnapshot.exists()) {
          setAdmin(true);
        } else {
          setAdmin(false);
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching Admin data:", error);
      }
    };
    fetchAdminData();
  }, [userPhoneNumber, navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "users"));
        const userData = querySnapshot.docs.map((doc) => doc.data());
        setUsers(userData);
        setLoading(false);
        //console.log(JSON.stringify(userData, null, 2));
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    if (activeTab === "users") {
      fetchUserData();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "payments"));
        const paymentData = querySnapshot.docs.map((doc) => doc.data());
        setPayments(paymentData);
        setLoadingPayments(false);
        //console.log(JSON.stringify(paymentData, null, 2));
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setLoadingPayments(false);
      }
    };

    if (activeTab === "payments") {
      fetchPaymentData();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchCouponData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "coupons"));
        const couponData = querySnapshot.docs.map((doc) => doc.data());
        //console.log(couponData)
        setCoupons(couponData);
        setLoadingCoupons(false);
        //console.log(JSON.stringify(couponData, null, 2));
      } catch (error) {
        console.error("Error fetching coupon data:", error);
        setLoadingCoupons(false);
      }
    };

    if (activeTab === "coupons") {
      fetchCouponData();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchWaitlistData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "waitlist"));
        const waitlistData = querySnapshot.docs.map((doc) => doc.data());
        setWaitlist(waitlistData);
        setLoadingWaitlist(false);
        console.log(JSON.stringify(waitlistData, null, 2));
      } catch (error) {
        console.error("Error fetching waitlist data:", error);
        setLoadingWaitlist(false);
      }
    };

    if (activeTab === "waitlist") {
      fetchWaitlistData();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const querySnapshot = await getDocs(collection(db, "orders"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (isAdmin) {
          setOrders(data);
        } else {
          setOrders([]);
        }
        setLoading(false);
        //console.log(JSON.stringify(data, null, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (!isLoggedIn) {
      navigate("/orderadminpanel/login");
    } else {
      fetchData();
    }
  }, [isAdmin, isLoggedIn, navigate]);

  return (
    <div className="horizontal-scroll">
      {isLoggedIn && isAdmin && (
        <>
          <NavBar />
          <ToastContainer />
          <div
            className="tab-container"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <div
              className="tabs"
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "300px",
              }}
            >
              <button
                className={activeTab === "orders" ? "active" : ""}
                onClick={() => setActiveTab("orders")}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                  border: "none",
                  color: activeTab === "orders" ? "#E98146" : "black",
                }}
              >
                Orders
              </button>
              <button
                className={activeTab === "users" ? "active" : ""}
                onClick={() => setActiveTab("users")}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                  border: "none",
                  color: activeTab === "users" ? "#E98146" : "black",
                }}
              >
                Users
              </button>
              <button
                className={activeTab === "coupons" ? "active" : ""}
                onClick={() => setActiveTab("coupons")}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                  border: "none",
                  color: activeTab === "coupons" ? "#E98146" : "black",
                }}
              >
                Coupons
              </button>
              <button
                className={activeTab === "payments" ? "active" : ""}
                onClick={() => setActiveTab("payments")}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                  border: "none",
                  color: activeTab === "payments" ? "#E98146" : "black",
                }}
              >
                Payments
              </button>
              <button
                className={activeTab === "waitlist" ? "active" : ""}
                onClick={() => setActiveTab("waitlist")}
                style={{
                  fontSize: "16px",
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                  border: "none",
                  color: activeTab === "waitlist" ? "#E98146" : "black",
                }}
              >
                Waitlist
              </button>
            </div>

            <div
              className="tab-content"
              style={{ backgroundColor: "#FFF1EA", width: "90%" }}
            >
              {activeTab === "orders" && (
                <div
                  className="table-container"
                  style={{
                    backgroundColor: "#FFF1EA",
                    marginLeft: "1px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 70px 10px 70px",
                      }}
                    >
                      <button
                        onClick={() => downloadCSV_orders(orders)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          height: "3vh",
                          backgroundColor: "#FFF1EA",
                          border: "1px solid #E98146",
                          marginRight: "10px",
                          padding: "0 10px",
                          width: "auto",
                        }}
                      >
                        EXPORT
                      </button>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img
                          src={filterIcon}
                          alt="Filter Icon"
                          style={{
                            marginRight: "5px",
                            width: "25px",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowFilter(!showFilter)}
                        />
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "16px",
                            height: "3vh",
                            backgroundColor: "#FFF1EA",
                            border: "1px solid #E98146",
                            marginRight: "10px",
                            padding: "0 10px",
                            width: "auto",
                          }}
                          onClick={handleButtonClick}
                        >
                          Add Order
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept=".csv"
                          onChange={handleCSVImportOrders}
                        />
                      </div>
                    </div>

                    {showFilter && (
                      <div
                        className="filterContainer"
                        style={{
                          marginTop: "-10px",
                          marginLeft: "1000px",
                          border: "none",
                          backgroundColor: "white",
                        }}
                      >
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "All"}
                            onClick={() => handleFilterChange("All")}
                            name="radio"
                            value="All"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">All</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "COD"}
                            onClick={() => handleFilterChange("COD")}
                            name="radio"
                            value="COD"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">COD</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "Online"}
                            onClick={() => handleFilterChange("Online")}
                            name="radio"
                            value="Online"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Online</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "PAYMENT_FAILED"}
                            onClick={() => handleFilterChange("PAYMENT_FAILED")}
                            name="radio"
                            value="Failed"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Failed</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "ORDER_IN_TRANSIT"}
                            onClick={() =>
                              handleFilterChange("ORDER_IN_TRANSIT")
                            }
                            name="radio"
                            value="Success"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Success</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue === "PAYMENT_PENDING"}
                            onClick={() =>
                              handleFilterChange("PAYMENT_PENDING")
                            }
                            name="radio"
                            value="Pending"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Pending</span>
                        </label>
                      </div>
                    )}
                  </div>

                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div style={{ overflowX: "auto", width: "100%" }}>
                      <table
                        style={{
                          backgroundColor: "white",
                          width: "95%",
                          maxWidth: "100%",
                          margin: "10px auto",
                          borderRadius: "10px",
                          padding: "10px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            {[
                              "S.No.",
                              "Name",
                              "Phone Number",
                              "Order ID",
                              "Date",
                              "Price",
                              "Payment Method",
                              "Status",
                              "Details",
                            ].map((header, index) => (
                              <th
                                key={index}
                                style={{
                                  textAlign: "start",
                                  color: "#FF9A61",
                                  padding: "12px",
                                  minWidth: "100px",
                                  borderBottom: "2px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((orderObj, index) => {
                            const orderKey = Object.keys(orderObj)[0];
                            const order = orderObj[orderKey];

                            return (
                              order && (
                                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                  <td style={{ padding: "12px" }}>{index + 1}</td>
                                  <td style={{ padding: "12px" }}>{order?.name ?? ""}</td>
                                  <td style={{ padding: "12px" }}>
                                    {order?.phoneNumber ?? order?.phone ?? ""}
                                  </td>
                                  <td style={{ padding: "12px" }}>{order?.orderId ?? ""}</td>
                                  <td style={{ padding: "12px" }}>
                                    {order.updated_time && order.updated_time.seconds
                                      ? moment.unix(order.updated_time.seconds).format("DD-MM-YYYY")
                                      : ""}
                                  </td>
                                  <td style={{ padding: "12px" }}>{order?.price ?? ""}</td>
                                  <td style={{ padding: "12px" }}>{order?.paymentMethod ?? ""}</td>
                                  <td style={{ padding: "12px" }}>{order?.rzp_status ? order?.rzp_status :  order?.status}</td>
                                  <td style={{ padding: "12px" }}>
                                    <button
                                      onClick={() => handleDetailsClick(order)}
                                      style={{
                                        backgroundColor: "#FF8E4D",
                                        color: "white",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        outline: "none",
                                        fontSize: "1.2em",
                                        fontStyle: "bold",
                                      }}
                                    >
                                      Details
                                    </button>
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                  )}
                </div>
              )}
              {activeTab === "users" && (
                <div
                  className="user-container"
                  style={{
                    backgroundColor: "#FFF1EA",
                    marginLeft: "1px",
                    width: "100%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 70px 10px 70px",
                      }}
                    >
                      <button
                        onClick={() => downloadCSV_users(users)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          height: "3vh",
                          backgroundColor: "#FFF1EA",
                          border: "1px solid #E98146",
                          marginRight: "10px",
                          padding: "0 10px",
                          width: "auto",
                        }}
                      >
                        EXPORT
                      </button>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img
                          src={filterIcon}
                          alt="Filter Icon"
                          style={{
                            marginRight: "5px",
                            width: "25px",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowFilter1(!showFilter1)}
                        />
                        <button
                          // className={showFilter1 ? "active" : ""}
                          // onClick={() => setShowFilter1(!showFilter1)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "16px",
                            height: "3vh",
                            backgroundColor: "#FFF1EA",
                            border: "1px solid #E98146",
                            // color: showFilter1 ? '#E98146' : 'black',
                            marginRight: "10px",
                            padding: "0 10px",
                            width: "auto",
                          }}
                          onClick={handleButtonClick}
                        >
                          Add User
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept=".csv"
                          onChange={handleCSVImportUsers}
                        />
                      </div>
                    </div>

                    {showFilter1 && (
                      <div
                        className="filterContainer"
                        style={{
                          marginTop: "-10px",
                          marginLeft: "1000px",
                          border: "none",
                          backgroundColor: "white",
                          padding: "20px",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            marginTop: "-10px",
                          }}
                        >
                          Platform
                        </p>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue.includes("Android")}
                            onClick={() => handleFilterChange1("Android")}
                            name="radio"
                            value="App"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">App</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue.includes("web")}
                            onClick={() => handleFilterChange1("web")}
                            name="radio"
                            value="COD"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Web</span>
                        </label>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            marginBottom: "5px",
                          }}
                        >
                          User Type
                        </p>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue1.includes("Normal")}
                            onClick={() => handleFilterChange2("Normal")}
                            name="radio1"
                            value="Normal"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Normal</span>
                        </label>
                        <label className="container">
                          <input
                            type="radio"
                            checked={filterValue1.includes("Merchant")}
                            onClick={() => handleFilterChange2("Merchant")}
                            name="radio1"
                            value="Merchant"
                          />
                          <span className="checkmark"></span>
                          <span className="filterTxt">Merchant</span>
                        </label>
                      </div>
                    )}
                  </div>

                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div style={{ overflowX: "auto", width: "100%" }}>
                      <table
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          maxWidth: "100%",
                          margin: "10px auto",
                          borderRadius: "10px",
                          padding: "10px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            {[
                              "S.No.",
                              "Name",
                              "Phone",
                              "Signup Time",
                              "Platform",
                              "Referral Code",
                              "Details",
                            ].map((header, index) => (
                              <th
                                key={index}
                                style={{
                                  textAlign: "start",
                                  color: "#FF9A61",
                                  padding: "12px",
                                  minWidth: "120px",
                                  borderBottom: "2px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user, index) => (
                            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                              <td style={{ padding: "12px" }}>{index+1}</td>
                              <td style={{ padding: "12px" }}>{user.userName}</td>
                              <td style={{ padding: "12px" }}>{user.phoneNumber}</td>
                              <td style={{ padding: "12px" }}>
                              {user.timestamp
                            ? new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }).format(user.timestamp.toDate())
                            : ""}
                              </td>
                              <td style={{ padding: "12px" }}>{user.platform}</td>
                              <td style={{ padding: "12px" }}>{user.referral_code}</td>
                              <td style={{ padding: "12px" }}>
                                <button
                                  onClick={() => handleDetailsClick1(user)}
                                  style={{
                                    backgroundColor: "#FF8E4D",
                                    color: "white",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    border: "none",
                                    outline: "none",
                                    fontSize: "1.2em",
                                    fontStyle: "bold",
                                  }}
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {activeTab === "coupons" && (
                <div>
                  <button
                    onClick={handleCouponButtonClick}
                    style={{
                      backgroundColor: "#FF9A61",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginLeft: "960px",
                      marginTop: "10px",
                    }}
                  >
                    Generate Coupon Code
                  </button>

                  <div
                    className="coupon-container"
                    style={{
                      backgroundColor: "#FFF1EA",
                      marginLeft: "1px",
                      width: "100%",
                    }}
                  >
                    {loadingCoupons ? (
                      <p>Loading...</p>
                    ) : (
                      <table
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          marginLeft: "70px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: "20%",
                                textAlign: "start",
                                color: "#FF9A61",
                              }}
                            >
                              Maximum
                            </th>
                            <th
                              style={{
                                width: "20%",
                                textAlign: "start",
                                color: "#FF9A61",
                              }}
                            >
                              Percentage
                            </th>
                            <th
                              style={{
                                width: "20%",
                                textAlign: "start",
                                color: "#FF9A61",
                              }}
                            >
                              Operational
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupons.map((coupon, index) => (
                            <tr key={index}>
                              <td style={{ width: "20%" }}>
                                {coupon.discount.max}
                              </td>
                              <td style={{ width: "20%" }}>
                                {coupon.discount.percentage}
                              </td>
                              <td style={{ width: "20%" }}>
                                {coupon.operational ? "true" : "false"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              )}

              {activeTab === "payments" && (
                <div
                  className="payment-container"
                  style={{
                    backgroundColor: "#FFF1EA",
                    marginLeft: "1px",
                    width: "100%",
                  }}
                >
                  {loadingPayments ? (
                    <p>Loading...</p>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "20px 70px 10px 70px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            onClick={() => downloadCSV_users(payments)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              height: "3vh",
                              backgroundColor: "#FFF1EA",
                              border: "1px solid #E98146",
                              marginRight: "10px",
                              padding: "0 10px",
                              width: "auto",
                            }}
                          >
                            EXPORT
                          </button>
                        </div>
                      </div>
                      <table
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          marginLeft: "70px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: "20%",
                                textAlign: "start",
                                color: "#FF9A61",
                              }}
                            >
                              Payment Method
                            </th>
                            <th
                              style={{
                                width: "20%",
                                textAlign: "start",
                                color: "#FF9A61",
                              }}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.map((payment, index) => (
                            <tr key={index}>
                              <td style={{ width: "20%" }}>{payment.method}</td>
                              <td style={{ width: "20%" }}>{payment.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {activeTab === "waitlist" && (
                <div
                  className="waitlist-container"
                  style={{
                    backgroundColor: "#FFF1EA",
                    marginLeft: "1px",
                    width: "100%",
                  }}
                >
                  {loadingWaitlist ? (
                    <p>Loading...</p>
                  ) : (
                    <table
                      style={{
                        backgroundColor: "white",
                        width: "90%",
                        marginLeft: "70px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "20%",
                              textAlign: "start",
                              color: "#FF9A61",
                            }}
                          >
                            Feature Request
                          </th>
                          <th
                            style={{
                              width: "20%",
                              textAlign: "start",
                              color: "#FF9A61",
                            }}
                          >
                            Name
                          </th>
                          <th
                            style={{
                              width: "20%",
                              textAlign: "start",
                              color: "#FF9A61",
                            }}
                          >
                            Phone Number
                          </th>
                          <th
                            style={{
                              width: "20%",
                              textAlign: "start",
                              color: "#FF9A61",
                            }}
                          >
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {waitlist.map((entry, index) => (
                          <tr key={index}>
                            <td style={{ width: "20%" }}>
                              {entry.feature_request}
                            </td>
                            <td style={{ width: "20%" }}>{entry.name}</td>
                            <td style={{ width: "20%" }}>{entry.phone}</td>
                            <td style={{ width: "20%" }}>
                              {entry.time
                                ? new Date(
                                    entry.time.seconds * 1000
                                  ).toLocaleString()
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
          <Footer />

          {showCouponDialog && (
            <div className="modal">
              <div className="modal-content">
                <span
                  className="close"
                  onClick={() => setShowCouponDialog(false)}
                >
                  &times;
                </span>
                <h2
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Generate Coupon Code
                </h2>
                <p>Coupon Name</p>
                <textarea
                  placeholder="Coupon Name"
                  value={coupon_name}
                  onChange={(e) => setCoupon_name(e.target.value)}
                  style={{
                    width: "95%",
                    height: "20px",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "none",
                    marginBottom: "20px",
                    backgroundColor: "#FFECE3",
                  }}
                />
                <p>Maximum</p>
                <textarea
                  placeholder="Maximum"
                  value={maximum}
                  onChange={(e) => setMaximum(e.target.value)}
                  style={{
                    width: "95%",
                    height: "20px",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "none",
                    marginBottom: "20px",
                    backgroundColor: "#FFECE3",
                  }}
                />
                <p>Percentage</p>
                <textarea
                  placeholder="Percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  style={{
                    width: "95%",
                    height: "20px",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "none",
                    marginBottom: "20px",
                    backgroundColor: "#FFECE3",
                  }}
                />
                <p>Operational</p>
                <textarea
                  placeholder="Operational"
                  value={operational}
                  onChange={(e) => setOperational(e.target.value)}
                  style={{
                    width: "95%",
                    height: "20px",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "none",
                    marginBottom: "20px",
                    backgroundColor: "#FFECE3",
                  }}
                />

                <button
                  // disabled={!remark}
                  onClick={generateCoupon}
                  style={{
                    backgroundColor: "#FF8E4D",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    border: "none",
                    outline: "none",
                    marginLeft: "250px",
                  }}
                >
                  Generate
                </button>
              </div>
            </div>
          )}
          
          {showUserDialog && ( // Assuming you have a state variable named showAnotherDialog
            <div className="modal1">
              <div className="modal1-content">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <button onClick={() => setShowUserDialog(false)}>
                    <img src={closeIcon} alt="Close" />
                  </button> */}
                </div>
                {selectedUserDetails && (
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "30px",
                      }}
                    >
                      USER DETAILS
                    </h3>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Name:</strong> {selectedUserDetails.userName}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Phone Number:</strong> {selectedUserDetails?.phoneNumber ?? selectedUserDetails?.phone ?? ""}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Platform:</strong> {selectedUserDetails.platform ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Referral Code:</strong> {selectedUserDetails.referral_code ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Invited By  :</strong> {selectedUserDetails.invitedBy ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>User Type:</strong> {selectedUserDetails.userType ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>DOB:</strong> {selectedUserDetails.dateOfBirth ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>City:</strong> {selectedUserDetails.currentCity ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize: "1.25em" }}>
                      <strong>Signup Time :</strong> 
                      {selectedUserDetails.timestamp
                            ? new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }).format(selectedUserDetails.timestamp.toDate())
                            : "N/A"}
                    </div>
                    <button
                      onClick={() => setShowUserDialog(false)}
                      style={{
                        backgroundColor: "#FF8E4D",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        marginTop: "5%",
                        marginBottom: "2%",
                        marginLeft: "40%",
                        fontSize: "1.2em",
                        fontWeight: "bold",
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* {showDialog && (
            <div className="dialog">
              <div className="dialog-content">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button onClick={() => setShowDialog(false)}>
                    <img src={closeIcon} alt="Close" />
                  </button>
                </div>
                {selectedOrderDetails && (
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Order Details
                    </h3>
                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        id="name"
                        value={selectedOrderDetails.name}
                        onChange={(e) =>
                          handleOrderInputChange("name", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <input
                        type="text"
                        id="phoneNumber"
                        value={
                          selectedOrderDetails?.phoneNumber ??
                          selectedOrderDetails?.phone ??
                          ""
                        }
                        onChange={(e) =>
                          handleOrderInputChange("phoneNumber", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="orderId">Order Id:</label>
                      <input
                        type="text"
                        id="orderId"
                        value={selectedOrderDetails.orderId}
                        onChange={(e) =>
                          handleOrderInputChange("orderId", e.target.value)
                        }
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="price">Price:</label>
                      <input
                        type="text"
                        id="price"
                        value={selectedOrderDetails.price}
                        onChange={(e) =>
                          handleOrderInputChange("price", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="status">Status:</label>
                      <input
                        type="text"
                        id="status"
                        value={selectedOrderDetails.status}
                        onChange={(e) =>
                          handleOrderInputChange("status", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="paymentMethod">Payment Method:</label>
                      <input
                        type="text"
                        id="paymentMethod"
                        value={selectedOrderDetails.paymentMethod ?? ""}
                        onChange={(e) =>
                          handleOrderInputChange("paymentMethod", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="puja">Puja:</label>
                      <input
                        type="text"
                        id="puja"
                        value={
                          selectedOrderDetails.pujaname ??
                          selectedOrderDetails?.description ??
                          ""
                        }
                        onChange={(e) =>
                          handleOrderInputChange("puja", e.target.value)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <label htmlFor="date">Date:</label>
                      <input
                        type="text"
                        id="date"
                        value={
                          selectedOrderDetails.updated_time &&
                          selectedOrderDetails.updated_time.seconds
                            ? moment
                                .unix(selectedOrderDetails.updated_time.seconds)
                                .format("DD-MM-YYYY")
                            : ""
                        }
                        onChange={(e) =>
                          handleOrderInputChange("date", e.target.value)
                        }
                      />
                    </div>

                    <p>Remark {}</p>
                    <textarea
                      placeholder="Remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      style={{
                        width: "95%",
                        height: "20px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        resize: "none",
                        marginBottom: "20px",
                        backgroundColor: "#FFECE3",
                      }}
                    />
                    <button
                      onClick={handleUpdateOrder}
                      // disabled={!remark}
                      style={{
                        backgroundColor: "#FF8E4D",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        marginLeft: "250px",
                      }}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          )} */}

          {/*order details*/}
          {showDialog && (
            <div className="dialog">
              <div className="dialog-content">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <button onClick={() => setShowDialog(false)}>
                    <img src={closeIcon} alt="Close" />
                  </button> */}
                </div>
                {selectedOrderDetails && (
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "30px",
                      }}
                    >
                      ORDER DETAILS
                    </h3>

                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Order Id  :</strong> {selectedOrderDetails.orderId}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Name  :</strong> {selectedOrderDetails.name}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Phone Number  :</strong> {selectedOrderDetails?.phoneNumber ?? selectedOrderDetails?.phone ?? ""}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Payment Method  :</strong> {selectedOrderDetails.paymentMethod ?? ""}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Price :</strong> {selectedOrderDetails.price}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Status  :</strong> {selectedOrderDetails.status ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>RZP Status  :</strong> {selectedOrderDetails.rzp_status ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Platform  :</strong> {selectedOrderDetails.platform}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Offering details  :</strong> {selectedOrderDetails.pujaname ?? selectedOrderDetails?.description ?? ""}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Puja ID  :</strong> {selectedOrderDetails.pujaId ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Offering ID  :</strong> {selectedOrderDetails.offeringId ?? "N/A"}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Date of booking  :</strong> {selectedOrderDetails.updated_time && selectedOrderDetails.updated_time.seconds ? moment.unix(selectedOrderDetails.updated_time.seconds).format("DD-MM-YYYY") : ""}
                    </div>
                    <div style={{ margin: "10px", fontSize : "1.25em" }}>
                      <strong>Address  :</strong> {selectedOrderDetails?.address 
                                                    ? [
                                                        selectedOrderDetails.address.house, 
                                                        selectedOrderDetails.address.locality, 
                                                        selectedOrderDetails.address.city, 
                                                        selectedOrderDetails.address.pincode
                                                      ].filter(Boolean).join(", ") 
                                                    : "N/A"}
                    </div>

                    {/* <p>Remark</p>
                    <textarea
                      placeholder="Remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      style={{
                        width: "95%",
                        height: "20px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        resize: "none",
                        marginBottom: "20px",
                        backgroundColor: "#FFECE3",
                      }}
                    /> */}
                    <button
                      // onClick={handleUpdateOrder}
                      onClick={() => setShowDialog(false)}
                      style={{
                        backgroundColor: "#FF8E4D",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        marginTop : "5%",
                        marginBottom : "2%",
                        marginLeft : "40%",
                        fontSize: "1.2em",
                        fontStyle: "bold",
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          <style jsx>{`
            .dialog {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .dialog-content {
              background-color: white;
              padding: 5px;
              width: 40%;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
          `}</style>

          <style jsx>{`
            /* CSS for modal dialog */
            .modal {
              display: block;
              position: fixed;
              z-index: 1;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgba(0, 0, 0, 0.4);
            }

            .modal-content {
              background-color: #fefefe;
              margin: 10% auto;
              padding: 20px;
              border: 1px solid #888;
              width: 40%;
            }

            .close {
              color: red;
              float: right;
              font-size: 28px;
              font-weight: bold;
              cursor: pointer;
              border-radius: 100%; /* Adding border radius to make it circular */
            }

            .close:hover,
            .close:focus {
              color: black;
              text-decoration: none;
            }
          `}</style>

          <style jsx>{`
            .modal1 {
              display: block;
              position: fixed;
              z-index: 1;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgba(0, 0, 0, 0.4);
            }

            .modal1-content {
              background-color: #fefefe;
              margin: 10% auto;
              padding: 20px;
              border: 1px solid #888;
              width: 40%;
            }

            .close {
              color: red;
              float: right;
              font-size: 28px;
              font-weight: bold;
              cursor: pointer;
              border-radius: 100%;
            }

            .close:hover,
            .close:focus {
              color: black;
              text-decoration: none;
            }

            /* You can add more styles here for the content of the second dialog box */
          `}</style>

          <style jsx>{`
            .filterContainer {
              border: 1px solid gray;
              width: 123px;
              padding: 8px 12px;
              width: 122px;
              height: 166px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            }
            .filterTxt {
              padding-left: 12px;
              padding-top: 3px;
            }
            /* The container */
            .container {
              display: block;
              cursor: pointer;
              font-size: 14px;
              color: #555c70;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              display: flex;
            }

            /* Hide the browser's default radio button */
            .container input {
              opacity: 0;
              cursor: pointer;
            }

            /* Create a custom radio button */
            .checkmark {
              height: 16px;
              width: 16px;
              border: 2px solid #e98146;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            /* On mouse-over, add a grey background color */
            .container:hover input ~ .checkmark {
              background-color: #e98146;
            }

            /* When the radio button is checked, add a blue background */
            .container input:checked ~ .checkmark {
              background-color: #e98146;
            }

            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
              content: "";
              display: none;
            }

            /* Show the indicator (dot/circle) when checked */
            .container input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the indicator (dot/circle) */
            .container .checkmark:after {
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background: #e98146;
              border: 2px solid white;
            }
          `}</style>
        </>
      )}
    </div>
  );
};

export default Dashboard;