import React, { useEffect, useState } from 'react';

import styles from '../../styles/screens/Influencer/InfluencerDashboard.module.css';
import InfluencerHeader from '../../components/InflunencerHomepage/InfluencerHeader';
import InfluencerFooter from '../../components/InflunencerHomepage/InfluencerFooter';
import share from "../../assets/amritjal_hp/Share.png"
import link from "../../assets/amritjal_hp/Link.png"
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../utils/firestoredb';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from "posthog-js/react";
import WhatsAppSupport from '../../components/Support/WhatsAppSupport';

const InfluencerDashboard = () => {
  const [showBankDialog, setShowBankDialog] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    name: '',
    bankName: '',
    accountNumber: '',
    ifsc: '',
    upi: '',
  });
  const navigate = useNavigate();
  const userName = JSON.parse(localStorage.getItem("influencerUserName"));
  console.log(userName);
  const [profileData, setProfileData] = useState({});
  const [earningsData, setEarningData] = useState([]);
  const posthog = usePostHog();

  if(!userName) {
    navigate("/dharmikx/influencer/");
  };

  useEffect (() => {
    const fetchInfluencerData = async () => {
      const userRef = doc(db, "dharmikxcoupons",userName);
      const userSnap = await getDoc(userRef);
      if(userSnap.exists()) {
        const userData = userSnap.data();
        const InfoData =  {
          name: userData.username,
          instagram: userData.instagram,
          phone: userData.phone,
          email: userData.email,
          userCode: userData.code,
          commission: userData.commission,
          imageUrl: userData.image,
          shareLink: userData.sharelink,
        };
        setProfileData(InfoData);
      }else {
        navigate("/dharmikx/influencer/");
      }
    };
    fetchInfluencerData();
  }, [userName, navigate]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const dashRef = doc(db, "dharmikxinfluencerledger", userName);
      const dashSnap = await getDoc(dashRef);
      if(dashSnap.exists()) {
        const dashData = dashSnap.data();
        setEarningData(dashData.ledger);
      }else {
        navigate("/dharmikx/influencer/");
      }
    };
    fetchDashboardData();
  }, [userName, navigate]);

    useEffect(() => {
        posthog.capture("$pageview");
      }, [posthog]);

  const handleBankDetailsChange = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankDetailsSubmit = (e) => {
    e.preventDefault();
    setShowBankDialog(false);
    // Add your bank details submission logic here
  };

  const totalEarnings = earningsData.reduce((sum, item) => sum + (item.price*((profileData.commission/100))), 0);



  // const handleEdit = () => {
  //   // Implement edit functionality
  //   console.log('Edit clicked');
  // };

  const handleCopy = () => {
    navigator.clipboard.writeText(profileData.shareLink);
    alert('Link copied to clipboard!');
  };

  const handleShare = () => {
    // Implement share functionality
    console.log('Share clicked');
  };

  const updateBankDetails = async () => {
    const userRef = doc(db, "dharmikxcoupons", userName);
    const userSnap = await getDoc(userRef);
    if(userSnap.exists()) {
      const userBankDetails = {
        account_number : bankDetails.accountNumber,
        bank_name : bankDetails.bankName,
        ifsc_code : bankDetails.ifsc,
        name : bankDetails.name,
        upi_id : bankDetails.upi,
      };

      try {
        await setDoc(
          userRef,{
            bank_details: userBankDetails,
          }
        , {merge:true});
      }catch(error) { 
        console.error("Error in updating the Bank Details : ", error);
      }
    }
  };

  return (
    <>
    <InfluencerHeader/>
    <WhatsAppSupport/>
    <div className={styles.profileCard}>
      <div className={styles.profileHeader}>
        <h1>Profile Details</h1>
      </div>
      
      <div className={styles.profileContent}>
        <div className={styles.profileImage}>
          <img src={profileData.imageUrl || "https://github.com/shadcn.png"} alt="Profile" />
        </div>
        
        <div className={styles.profileDetails}>
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>Name:</span>
            <span className={styles.detailValue}>{profileData.name}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>Instagram:</span>
            <span className={styles.detailValue}>{profileData.instagram}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>Phone Number:</span>
            <span className={styles.detailValue}>{profileData.phone}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>Email ID:</span>
            <span className={styles.detailValue}>{profileData.email}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>User Code:</span>
            <span className={styles.detailValue}>{profileData.userCode}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.detailLabel}>Commission %:</span>
            <span className={styles.detailValue}>{profileData.commission}</span>
            {/* <Pencil className={styles.editIcon} size={20} onClick={handleEdit} /> */}
          </div>
        </div>
      </div>
      
      <div className={styles.shareSection}>
        <span className={styles.shareLink}>{profileData.shareLink}</span>
        <div className={styles.shareIcons}>
          <button className={styles.shareButton} onClick={handleCopy}>
         <img src={share} alt="share" style={{width:"20px", height:"20px"}}/>
          </button>
          <button className={styles.shareButton} onClick={handleShare}>
           <img src={link} alt="link" style={{width:"20px", height:"20px"}} />
          </button>
        </div>
      </div>
    </div>
    <div className={styles.earningsContainer}>
      <table className={styles.earningsTable}>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Product</th>
            <th>Price</th>
            <th>Commission %</th>
            <th>Earnings</th>
          </tr>
        </thead>
        <tbody>
          {earningsData.map((item, index) => (
            <tr key={index}>
              <td>{index+1}.</td>
              <td>{item.name}</td>
              <td>{item.product}</td>
              <td>Rs.{item.price}</td>
              <td>{profileData.commission}</td>
              <td>Rs.{item.price*((profileData.commission/100))}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div className={styles.buttonsContainer}>
        <div className={styles.totalEarnings}>
          Total Earnings : RS.{totalEarnings}
        </div>
        <button 
          className={styles.updateButton}
          onClick={() => setShowBankDialog(true)}
        >
          Update Bank Details
        </button>
        {showBankDialog && (
        <div className={styles.dialogOverlay}>
          <div className={styles.bankDetailsDialog}>
            <h3>Update Bank Details</h3>
            <form onSubmit={handleBankDetailsSubmit}>
              <div className={styles.formGroup}>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={bankDetails.name}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  value={bankDetails.bankName}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Account Number</label>
                <input
                  type="text"
                  name="accountNumber"
                  value={bankDetails.accountNumber}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>IFSC Code</label>
                <input
                  type="text"
                  name="ifsc"
                  value={bankDetails.ifsc}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>UPI ID</label>
                <input
                  type="text"
                  name="upi"
                  value={bankDetails.upi}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className={styles.dialogButtons}>
                <button type="button" onClick={() => setShowBankDialog(false)}>
                  Cancel
                </button>
                <button type="submit" onClick={updateBankDetails}>Save</button>
              </div>
            </form>
          </div>
        </div>
      )}
      </div>
    </div>
    <InfluencerFooter/>
    </>
  );
};

export default InfluencerDashboard;
