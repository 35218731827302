import React, { useState } from "react";
import styles from "../../../styles/screens/AmritjalProductDetail/AmritjalSpray/Testimonials.module.css";
import amritjalTestimonials from  "../../../utils/Amritjal_reviews.json"
import amritjalRosetestimonials from "../../../utils/Amritjal_rose_reviews.json"
import vrindavanHoliPrasadBoxTestimonials from "../../../utils/Vrindavan_holi_prasad_box_reviews.json"

const Testimonials = ({productId}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const testimonials = productId === "amritjal-spray" ? amritjalTestimonials : (productId === "amritjal-rose" ? amritjalRosetestimonials : vrindavanHoliPrasadBoxTestimonials);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className={styles.testimonialsContainer}>
      <h1 className={styles.testimonialsTitle}>Testimonials</h1>

      {/* Desktop Layout */}
      <div className={`${styles.carouselContainer} ${styles.desktopLayout}`}>
        <button className={`${styles.navButton} ${styles.prev}`} onClick={prevSlide}>
          <span className={styles.navIcon}>&lt;</span>
        </button>

        <div className={styles.carouselContent}>
          <div className={styles.thumbnail}>
            <img
              src={testimonials[(currentSlide - 1 + testimonials.length) % testimonials.length].image || "/placeholder.svg"}
              alt="Testimonial thumbnail"
              className={styles.thumbnailImage}
            />
          </div>

          <div className={styles.mainSlide}>
            <img
              src={testimonials[currentSlide].image || "/placeholder.svg"}
              alt="Testimonial"
              className={styles.mainImage}
            />
          </div>

          <div className={styles.rightContent}>
            <div className={styles.testimonialInfo}>
              <h2 className={styles.name}>{testimonials[currentSlide].name}</h2>
              <p className={styles.location}>({testimonials[currentSlide].location})</p>
              <p className={styles.text}>{testimonials[currentSlide].comment}</p>
            </div>
            <div className={styles.thumbnail}>
              <img
                src={testimonials[(currentSlide + 1) % testimonials.length].image || "/placeholder.svg"}
                alt="Testimonial thumbnail"
                className={styles.thumbnailImage}
              />
            </div>
          </div>
        </div>

        <button className={`${styles.navButton} ${styles.next}`} onClick={nextSlide}>
          <span className={styles.navIcon}>&gt;</span>
        </button>
      </div>

      {/* Mobile Layout */}
      <div className={`${styles.carouselContainer} ${styles.mobileLayout}`}>
        <div className={styles.mainSlide}>
          <img
            src={testimonials[currentSlide].image || "/placeholder.svg"}
            alt="Testimonial"
            className={styles.mainImage}
          />
        </div>
       <div className={styles.rightContent}>
        <div className={styles.testimonialInfo}>
          <h2 className={styles.name}>{testimonials[currentSlide].name}</h2>
          <p className={styles.location}>({testimonials[currentSlide].location})</p>
          <p className={styles.text}>{testimonials[currentSlide].comment}</p>
        </div>
       <div className={styles.thumbnailMain}>
        <div className={styles.thumbnail}>
          <img
            src={testimonials[(currentSlide - 1 + testimonials.length) % testimonials.length].image || "/placeholder.svg"}
            alt="Previous testimonial thumbnail"
            className={styles.thumbnailImage}
          />
        </div>
        <div className={styles.thumbnail}>
          <img
            src={testimonials[(currentSlide + 1) % testimonials.length].image || "/placeholder.svg"}
            alt="Next testimonial thumbnail"
            className={styles.thumbnailImage}
          />
        </div>
        </div>

        <button className={`${styles.navButton} ${styles.prev}`} onClick={prevSlide}>
          <span className={styles.navIcon}>&lt;</span>
        </button>
        <button className={`${styles.navButton} ${styles.next}`} onClick={nextSlide}>
          <span className={styles.navIcon}>&gt;</span>
        </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
