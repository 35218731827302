import React from 'react';
import styles from '../../styles/screens/Influencer/InfluencerFooter.module.css';
import logo from '../../assets/amritjal_hp/logoIcon.png';
import duns from '../../assets/amritjal_hp/duns.png';
import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/linkedin.svg";

const InfluencerFooter = () => {
  return (
    <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <div className={styles.footerLeft}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo 1" className={styles.logo} />
          <div className={styles.divider}></div>
          <img src={duns} alt="Logo 2" className={styles.logo1} />
        </div>
        <p className={styles.description}>
          Dharmik is India's premier religious App, offering a cherished experience for Hindu devotees. Seamlessly
          book Online Puja and receive the divine Prasad. Your spiritual journey begins with Dharmik, where tradition
          meets technology.
        </p>
      </div>
      <div className={styles.footerRight}>
        <h3 className={styles.contactTitle}>Contact Us</h3>
        <a href="mailto:contact@dharmik.in" className={styles.contactLink}>contact@dharmik.in</a>
        <a href="tel:+917906671564" className={styles.contactLink}>+91 7906671564</a>
        <div className={styles.socialIcons}>
        <a href="https://www.facebook.com/GharKaMandir">
              <FacebookIcon className={styles.social_icon} />
            </a>
            <a href="https://www.instagram.com/dharmik_app/">
              <InstagramIcon className={styles.social_icon} />
            </a>
            <a href="https://twitter.com/dharmikapp">
              <TwitterIcon className={styles.social_icon} />
            </a>
            <a href="https://www.linkedin.com/company/dharmik-app/">
              <LinkedInIcon className={styles.social_icon} />
            </a>
        </div>
      </div>
    </div>
    <div className={styles.footerBottom}>
      <p>2025 Dharmik. All right reserved.</p>
      <div className={styles.footerLinks}>
        <a href="#aboutus">About Us</a>
        <a href="#aboutus">Privacy Policy</a>
        <a href="#aboutus">Terms of Service</a>
        <a href="#aboutus">Refund Policy</a>
      </div>
    </div>
  </footer>
  );
};

export default InfluencerFooter;
