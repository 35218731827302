import React, { useState, useEffect } from "react";
import styles from "../../../styles/screens/AmritjalProductDetail/AmritjalSpray/Offercard.module.css";
import fetchAmritJalProductStock from "../../../utils/DharmikxAmritJalBottleId";
import { useNavigate } from "react-router-dom";
import { doc, getDoc} from "firebase/firestore";
import { db } from "../../../utils/firestoredb";

const OfferCard = ({productId}) => {
  const timeLeftKey = "timeLeft" + productId;
  const [timeLeft, setTimeLeft] = useState(() => {
        const storedTime = sessionStorage.getItem(timeLeftKey);
        return storedTime ? parseInt(storedTime, 10) : 10 * 60;
      });
  const [isOfferExpired, setIsOfferExpired] = useState(false);
  const [productStock, setProductData] = useState(null);
  const [productPriceData, setProductPriceData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                  const updatedTime = Math.max(prevTime - 1, 0);
                  sessionStorage.setItem(timeLeftKey, updatedTime);
                  return updatedTime;
                });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsOfferExpired(true);
      sessionStorage.removeItem(timeLeftKey);
    }
  }, [timeLeft,timeLeftKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAmritJalProductStock(productId);
        setProductData(data);
      } catch (err) {
        console.log("Error in fetching Amrit Jal Product Stock!", err);
      }
    };
    fetchData();
  }, [productId]);

  useEffect(() => {
    const fetchProductData = async () => {
      const amritJalSprayDocRef = doc(db, "dharmikxproducts", productId);
      const amritJalSprayDocSnap = await getDoc(amritJalSprayDocRef);

      if (amritJalSprayDocSnap.exists()) {
        setProductPriceData(amritJalSprayDocSnap.data());
      } else {
        console.log("Error Fetching Puja Details for Amritjal spray: ");
      }
    };

    fetchProductData();
  }, [productId]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return { minutes, secs };
  };

  const { minutes, secs } = formatTime(timeLeft);

  if(productStock)
  return (
    <div
    className={
      productId === 'vindavan-holi-prasad-box'
        ? styles.offerContainerHoli
        : styles.offerContainer
    }
  >
  
      <div className={styles.offerContent}>
        <div className={styles.offerProgress}>
         {productId==="amritjal-spray" && <h2 className={styles.offerTitle}>{productStock.currentbottle} / {productStock.stockleft} Amrit Jal sold</h2>}
         {productId==="vindavan-holi-prasad-box" && <h2 className={styles.offerTitle}>{productStock.currentbottle}/ {productStock.stockleft} Vrindavan Prasad sold</h2>}
         {productId==="amritjal-rose" && <h2 className={styles.offerTitle}>{productStock.currentbottle} / {productStock.stockleft} Amrit Jal</h2>}
         {productId==="amritjal-rose" && <h2 className={styles.offerTitle}>(Rose Fragrance) Sold</h2>}
          <div className={styles.progressBar}>
            <div
              className={styles.progressBarFill}
              style={{ 
                width: `${(productStock.currentbottle / productStock.stockleft) * 100}%` 
              }}
            />
          </div>
        </div>

        <div className={styles.offerPrice}>
          <p
            className={`${styles.offerPriceOriginal} ${
              isOfferExpired ? styles.active : ""
            }`}
          >
           {`Limited Stock Live Price : Rs. ${productPriceData?.price?.original}`}
          </p>
          <p
            className={`${styles.offerPriceSpecial} ${
              isOfferExpired ? styles.crossed : ""
            }`}
          >
            {`OFFER PRICE : Rs. ${productPriceData?.price?.discounted}`}
          </p>
        </div>

        <div className={styles.offerTimer}>
          <p className={styles.offerTimerText}>
          {!isOfferExpired ? "Offer Ends in" : "Offer Ended" }
          </p>
          
            <div className={styles.timerDisplay}>
              <div className={styles.timerBox}>{String(minutes).padStart(2, "0")}</div>
              <span className={styles.timerSeparator}>:</span>
              <div className={styles.timerBox}>{String(secs).padStart(2, "0")}</div>
            </div>
    
        </div>
        <button className={styles.shopButton} onClick={()=>navigate(`/dharmikx/checkout/${productId}`)}>
        {productId === "vindavan-holi-prasad-box" ? "Book Now" : "Shop Now" }
        </button>
      </div>
    </div>
  );
};

export default OfferCard;
