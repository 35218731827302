import React, { useEffect, useState } from 'react';
import styles from '../../styles/screens/Trip/Journey.module.css';

const Journey = ({ title, timelineItems, desktopImageSrc,mobileImageSrc }) => {
  const [currentImage, setCurrentImage] = useState(desktopImageSrc);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaChange = (e) => {
      setCurrentImage(e.matches ? mobileImageSrc : desktopImageSrc);
    };
    handleMediaChange(mediaQuery); // Set initial image
    mediaQuery.addEventListener('change', handleMediaChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, [desktopImageSrc, mobileImageSrc]);
  return (
    <div className={styles.journeyContainer}>
      <div className={styles.imageSection}>
        <img src={currentImage} alt={title} />
      </div>
      <div className={styles.contentSection}>
        <h1 className={styles.title}>{title}</h1>

        {timelineItems.map((item, index) => (
          <div key={index} className={styles.timelineItem}>
            {item.title && <h3 className={styles.subtitle}>{item.title}</h3>}
            <div className={styles.timeBlock}>
              <span className={styles.time}>{item.time} :</span>
              <span className={styles.description}>{item.description}</span>
            </div>
            {item.subDescription && (
              <div className={styles.subDescription}>{item.subDescription}</div>
            )}
            {item.bulletPoints && (
              <ul className={styles.bulletPoints}>
                {item.bulletPoints.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            )}
            <div className={styles.divider}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Journey;
