// PrasadProduct.js
import React from "react";
import styles from "../../../styles/screens/AmritjalProductDetail/VrindavanPrasad/PrasadProduct.module.css";
import logo1 from "../../../assets/holiprasad/1-removebg-preview.png";
import logo2 from "../../../assets/holiprasad/2-removebg-preview.png";
import logo3 from "../../../assets/holiprasad/3-removebg-previe.png";
import logo4 from "../../../assets/holiprasad/4-removebg-previe.png";
import logo5 from "../../../assets/holiprasad/5-removebg-previe.png";
import logo6 from "../../../assets/holiprasad/6-removebg-previe.png";
import logo7 from "../../../assets/holiprasad/7-removebg-previe.png";
import logo8 from "../../../assets/holiprasad/8-removebg-preview.png";
import logo9 from "../../../assets/holiprasad/9-removebg-previe.png";
import logo10 from "../../../assets/holiprasad/10-removebg-preview.png";


const PrasadProduct = () => {
  const products = [
    { img: logo3, name: "Krishna Ji Gold Plated Charan Box" },
    { img: logo2, name: "Krishna Ji Moorti" },
    { img: logo1, name: "Shree Banke Bihari Peda Prasad" },
    { img: logo4, name: "Blessed Tulsi Mala" },
    { img: logo5, name: "Blessed Chandan" },
    { img: logo6, name: "Raksha Sutra" },
    { img: logo9, name: "Blessed Peacock Feather" },
    { img: logo8, name: "Krishna Ji Picture" },
    { img: logo7, name: "Blessed Dhoopbatti" },
    { img: logo10, name: "Radha Chalisa" },
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>All the above religious items are sourced and blessed from Vrindavan</h1>
      <div className={styles.productsContainer}>
        <div className={styles.productsGrid}>
          {products.map((product, index) => (
            <div key={index} className={styles.productItem}>
              <div className={styles.productImageContainer}>
                <img
                  src={product.img}
                  alt={product.name}
                  className={styles.productImage}
                />
              </div>
              <h3 className={styles.productName}>{product.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrasadProduct;
