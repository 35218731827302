import styles from "../../../styles/screens/AmritjalProductDetail/AmritjalRose/ProductLandingRose.module.css"
import { useNavigate } from "react-router-dom"
import amritjalRose from "../../../assets/amritjal_hp/amritjal-rose.png"
import amritjalClassic from "../../../assets/amritjal_hp/spray.png"
import rosePetals from "../../../assets/amritjal_hp/rose-petals.png"

const ProductLandingRose = ({ productId }) => {
  const navigate = useNavigate()

  const handleOrderNowClick = () => {
    navigate(`/dharmikx/checkout/${productId}`)
  }

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${rosePetals})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Main layout wrapper */}
      <div className={styles.layoutWrapper}>
        {/* Left section */}
        <div className={styles.leftSection}>
          <h1>Instant Calm<br />and Positivity</h1>
          <p>Elevate your mood and reduce stress with a refreshing spiritual mist anytime, anywhere.</p>
        </div>

        {/* Center section - Product Image */}
        <div className={styles.centerSection}>
          {/* Rose Fragrance Text */}
          {productId === "amritjal-rose" && (
            <div className={styles.roseFragrance}>
              Infused with<br />Rose Fragrance
            </div>
          )}
          
          {/* Product Image */}
          <img
            src={productId === "amritjal-rose" ? amritjalRose : amritjalClassic}
            alt="Product"
            className={styles.productImage}
          />
        </div>

        {/* Right section */}
        <div className={styles.rightSection}>
          <h2>Energize Your<br />Daily Routine</h2>
          <p>Infused with sacred ingredients, it revitalizes your energy and keeps you focused.</p>
          <button onClick={handleOrderNowClick} className={styles.orderButton}>
            ORDER NOW
          </button>
        </div>
      </div>

      {/* Bottom section - Left aligned under product image */}
      <div className={styles.bottomSection}>
        <div>
          <h2>Stay Connected<br />to Tradition</h2>
          <p>A modern way for youth to embrace spiritual purity and cultural roots on the go.</p>
        </div>
      </div>
    </div>
  )
}

export default ProductLandingRose

