import React from 'react'
import styles from '../../styles/screens/Trip/TripExclusion.module.css'
const TripExclusion = () => {
  return (
    <div className={styles.exclusionsSection}>
    <h2 className={styles.exclusionsTitle}>Exclusions</h2>
    <ul className={styles.exclusionsList}>
      <li>Food at cafes/ Restaurants (Except breakfast & late-night snacks at the camp.)</li>
      <li>Rafting (Rs.1000 per person)-to be added upon confirmation.</li>
      <li>Personal expenses (Shopping , extra snacks , drinks etc.)</li>
      <li>Medical expenses or travel insurance.</li>
      <li>Anything not specifically mentioned in the inclusions.</li>
      <li>Keep some extra cash handy for personal spends & optional activities !</li>
    </ul>
  </div>
  )
}

export default TripExclusion