import React, {useEffect, useState} from 'react';
import styles from "../../styles/screens/Amritjal_hp/Navbar.module.css";
import logo from '../../assets/amritjal_hp/Logo.png';
import { Link, useNavigate } from "react-router-dom";

const AmritjalNavbar = () => {
  const [phone, setPhone] = useState(null);
  const navigate = useNavigate();

  // const handleDharmikxClick = () => {
  //   navigate("/dharmikx");
  // }

  const navigateToProfile = () => {
    navigate("/profile");
  }
  
  const navigateToHome = () => {
    navigate("/dharmikx");
  }

  useEffect(() => {
      const phone = JSON.parse(localStorage.getItem("phoneNumber"));
      if (phone) {
        setPhone(phone);
      }
    }, []);

  return (
    <nav className={styles.navbar}>
      <div>
      <img src={logo} className={styles.logo} alt="Logo" onClick={navigateToHome}/>
      </div>
      <div className={styles.menu}>
       {/* <div className={styles.menuItem} onClick={handleDharmikxClick}>DharmikX</div> */}
        <Link to="https://blogs.dharmikapp.in">
        <div className={styles.menuItem}>Blogs</div>
        </Link>
        <div className={`${styles.menuItem} ${styles.loginButton}`}onClick={phone ? navigateToProfile : null}>{phone ? "Profile" : "Login"}</div>
      </div>
    </nav>
  );
};

export default AmritjalNavbar;
