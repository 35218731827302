import React from "react";
import styles from "../../styles/screens/Trip/TripCard.module.css";

const TripCard = () => {
  return (
    <div className={styles.tripCard}>
      <h1 className={styles.tripTitle}>Why this trip is a must-do?</h1>
      <div className={styles.tripInnerCard}>
      <ul className={styles.tripFeatures}>
        <li className={styles.featureItem}>
          <strong>Thrilling Adventures</strong>
          <span> – Rafting, scotty rides & more!</span>
        </li>
        <li className={styles.featureItem}>
          <strong>Spiritual Bliss</strong>
          <span> – Ganga Aarti, yoga, sound healing & temple visits!</span>
        </li>
        <li className={styles.featureItem}>
          <strong>Chill & Explore</strong>
          <span> – Café hopping, waterfalls, and bonfire nights!</span>
        </li>
        <li className={styles.featureItem}>
          <strong>Perfect Balance</strong>
          <span> – Adventure + Relaxation + Exploration = Epic Trip!</span>
        </li>
      </ul>
      </div>

      <p className={styles.tripTagline}>
        This isn't just a trip—it's an experience of a lifetime!
      </p>
    </div>
  );
};

export default TripCard;
