import React from 'react'
import styles from '../../../styles/screens/AmritjalProductDetail/VrindavanPrasad/PrasadHero.module.css'
import { useNavigate } from 'react-router-dom'

const PrasadHero = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/dharmikx/checkout/vindavan-holi-prasad-box');
  }
  return (
    <div className={styles.heroContainer}>
      <div className={styles.overlay}></div>
      <div className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1>Vrindavan</h1>
          <h2>Holi Prasad Box</h2>
          <button className={styles.bookNowBtn} onClick={handleNavigate}>Book Now</button>
        </div>
      </div>
    </div>
  )
}

export default PrasadHero