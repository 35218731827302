import React from "react";
import style from "../../../styles/screens/AmritjalProductDetail/AmritjalSpray/PerfumeShowcase.module.css";
import hand from "../../../assets/amritjal_hp/Bottleinhand.png";
import rosehand from "../../../assets/amritjal_hp/amritjal_rose_hand.png";

const PerfumeShowcase = ({productId}) => {
  return (
    <div className={style.container}>
      <h1 className={style.heading}>
        <span className={style.pure}>Pure</span>
        <span className={style.separator}>•</span>
        <span className={style.sacred}>Sacred</span>
        <span className={style.separator}>•</span>
        <span className={`${style.highlight} ${style.divine}`}>Divine</span>
        <span className={style.separator_divine}>•</span>
      </h1>

      <div className={style.imageContainer}>
        <img
          src={productId === "amritjal-spray" ? hand : rosehand}
          alt="Luxury perfume bottle"
          className={style.image}
        />
      </div>
    </div>
  );
};

export default PerfumeShowcase;
