import React from 'react';
import styles from '../../styles/screens/Influencer/components/CardSection.module.css';

const CardSection = () => {
  return (
    <div className={styles.benefitsContainer}>
      <h1 className={styles.benefitsTitle}>WHAT'S IN IT FOR YOU ?</h1>

      <div className={styles.benefitsGrid}>
        {/* First row - Two 500x400 cards */}
        <div className={styles.cardRow}>
          <div className={`${styles.benefitCard}`}>
            <h3>EARN 25% COMMISSION</h3>
            <p>
              Receive 25% of every product sold through your exclusive coupon code. Your influence, your rewards!
            </p>
          </div>
          <div className={`${styles.benefitCard}`}>
            <h3>EXCLUSIVE DISCOUNTS FOR YOUR FOLLOWERS</h3>
            <p>
              Share the love! Your followers get 10% off when they use your coupon code, making spirituality more accessible.
            </p>
          </div>
        </div>

        {/* Second row - 700x400 and 300x400 */}
        <div className={styles.singleColumnRow}>
          <div className={`${styles.benefitCard}`}>
            <h3>TRACK YOUR IMPACT</h3>
            <p>
              Share the love! Your followers get 10% off when they use your coupon code, making spirituality more accessible.
            </p>
          </div>
          <div className={`${styles.benefitCard} ${styles.orange}`}>
            <h3>MONTHLY PAYOUTS</h3>
            <p className={styles.descriptionColor}>Get rewarded every month with hassle-free payouts.</p>
          </div>
        </div>

        {/* Third row - Two 500x400 cards */}
        <div className={styles.thirdRow}>
          <div className={`${styles.benefitCard} ${styles.orange}`}>
            <h3>BOOST YOUR CONTENT</h3>
            <p className={styles.descriptionColor}>
              We'll boost your real created for Dhamik (posted in collaboration with us on our account), helping you gain
              more followers and reach.
            </p>
          </div>
          <div className={`${styles.benefitCard}`}>
            <h3>FREE SAMPLES OF LATEST LAUNCHES</h3>
            <p>
              Be the first to experience our latest products with exclusive free samples, just for you!
            </p>
          </div>
        </div>

        {/* Fourth row - Full width movement card */}
        <div className={`${styles.movementCard} ${styles.orange} ${styles.benefitCard}`}>
          <h2>BE PART OF THE DHAMIK MOVEMENT</h2>
          <p className={styles.descriptionColor}>
            Join a vibrant community of spiritual-modern influencers, collaborate on meaningful campaigns, and make a
            difference.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
