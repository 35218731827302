import React from 'react';
import styles from '../../../styles/screens/AmritjalProductDetail/AmritjalSpray/Authentication.module.css';
import bg from '../../../assets/amritjal_hp/aboutImage.jpeg';
import qr from '../../../assets/amritjal_hp/amritjal_authenticity_qr.png';
import { useNavigate } from 'react-router-dom';

const Authentication = () => {
  const navigate = useNavigate();

  const navigateToLedger = ()  =>{
    navigate("/ledger");
  }
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className={styles.flexContainer}>
        <div className={styles.qrSection}>
          <img
            src={qr}
            alt="QR Code"
            className={styles.qrImage}
          />
          <p className={styles.qrText}>Scan to watch the Product Journey</p>
        </div>
        <div className={styles.contentSection}>
          <h1 className={styles.heading}>Authentication</h1>
          <div className={styles.stepsContainer}>
            <div className={styles.step}>
              <p className={styles.stepDescription}>
                <strong>1) End-to-End Video Proof:</strong> Receive a video proof documenting the journey of your spray—from collection to final packaging—when you place an order.
              </p>
            </div>
            <div className={styles.step}>
              <p className={styles.stepDescription}>
                <strong>2) Unique Product Number:</strong> Each spray is part of our limited edition and comes with a unique product number for exclusivity.
              </p>
            </div>
            <div className={styles.step}>
              <p className={styles.stepDescription}>
                <strong>3) Transparency:</strong> A public ledger is displayed, allowing you to verify that your package is unique and individually made—no duplicates, ever.
              </p>
            </div>
            <div className={styles.step}>
              <p className={styles.stepDescription}>
                <strong>4) Authentication Scanner:</strong> An authentication scanner on the bottle ensures you receive the original product upon delivery.
              </p>
            </div>
            <div className={styles.step}>
              <p className={styles.stepDescription}>
                <strong>5) Certificate of Authenticity:</strong> Every spray includes a unique certificate, personally signed by the founder of Dharmik, guaranteeing its authenticity.
              </p>
            </div>
            <button className={styles.ledgerButton} onClick={navigateToLedger}>
              View Public Ledger
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
